#vehicle-project {
    .content {
        .card {
            background: #FFFFFF;
            margin: 16px auto 48px auto;
            padding: 24px;
            width: 1000px;
            height: 800px;
            p {
                font-size: 17px;
                line-height: 30px;
            }
        }
    }
}