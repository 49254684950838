.main-style {

    .work-group {
        margin-top: 20px;
    }

    .squarebrackets:before {
        content: " ";
        border-left: 2px solid #666;
        border-top: 2px solid #666;
        border-bottom: 2px solid #666;
        margin-right: 5px;
        padding: 10px 5px 10px 5px;
        border-radius: 4px;
    }

    .squarebrackets:after {
        content: " ";
        padding: 10px 5px 10px 5px;
        border-right: 2px solid #666;
        border-top: 2px solid #666;
        border-bottom: 2px solid #666;
        margin-left: 10px;
        border-radius: 4px;
    }

    .first-button-margin {
        margin: 6px 3px 6px 6px;
        line-height: 37px;
    }

    .highlight-box {
        @extend .md-light-blue-800-bg;
        border-radius: 2px;
        padding: 4px 8px;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
    }

    .highlight-box-green {
        @extend .md-light-green-800-bg;
        border-radius: 2px;
        padding: 4px 8px;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
    }

    .highlight-box-red {
         @extend .md-red-700-bg;
         border-radius: 2px;
         padding: 4px 8px;
         font-size: 11px;
         font-weight: 500;
         white-space: nowrap;
    }

    .fade-element-in.ng-enter {
        transition: 0.8s linear all;
        opacity: 0;
    }

    .fade-element-in-init .fade-element-in.ng-enter {
        opacity: 1;
    }

    .fade-element-in.ng-enter.ng-enter-active {
        opacity: 1;
    }

    .fade-element-in.ng-leave {
        transition: 0.3s linear all;
        opacity: 1;
    }

    .fade-element-in.ng-leave.ng-leave-active {
        opacity: 0;
    }

    .tab-content {
        overflow-x: hidden;
    }

    .sidenavTicketBilling {

        .md-autocomplete-suggestions-container {
            overflow-y: scroll !important;
        }

        .md-autocomplete-suggestions-container .md-virtual-repeat-scroller {
            position: static !important
        }

        .md-autocomplete-suggestions-container .md-virtual-repeat-sizer {
            height: 0 !important
        }

        .md-autocomplete-suggestions-container .md-virtual-repeat-offsetter {
            position: static !important
        }
    }

    .md-datepicker-input-container .md-datepicker-input {
        max-width: none;
        font-size: 15px;
    }

    .animationIf.ng-enter,
    .animationIf.ng-leave {
        max-height: 0;
        overflow-y: hidden;
        -webkit-transition: max-height 0.5s ease-in-out;
        -moz-transition: max-height 0.5s ease-in-out;
        -o-transition: max-height 0.5s ease-in-out;
        transition: max-height 0.5s ease-in-out;
    }

    .animationIf.ng-enter,
    .animationIf.ng-leave.ng-leave-active {
        max-height: 0;
    }

    .animationIf.ng-leave,
    .animationIf.ng-enter.ng-enter-active {
        max-height: 100px;
    }

    .md-datepicker-input-container {
        display: block;
        margin-left: 0 !important;
        width: 100% !important;
    }

    ._md-datepicker-floating-label>md-datepicker .md-datepicker-button {
        display: none;
    }

    ._md-datepicker-floating-label._md-datepicker-has-calendar-icon>label:not(.md-no-float):not(.md-container-ignore) {
        width: calc(100% - 20px);
    }

    .button-margin {
        margin: 6px 6px 6px 6px;
        line-height: 37px;
    }

    .md-content-error {
        font-family: consolas;
        font-size: 1.1em;
    }

    md-button {
        line-height: 37px;
    }

    .date-picker {
        padding-right: 18px;
        height: 58px !important;
        margin-top: 8px !important;

        label {
            left: 33px !important;
            top: -35px;
            right: 0 !important;
            width: calc(100% - 33px) !important;
        }

        md-datepicker {
            display: inline-block;
            width: 100%;
            margin-left: 26px;
            height: 0;

            .md-datepicker-button {
                float: left;
                margin-top: -12.5px;
                margin-left: -33px;
            }

            .md-datepicker-input-container {
                width: 100%;
                top: -14px;
                right: 17px;
                font-size: 12px !important;

                .md-datepicker-input {
                    max-width: 100% !important;
                    min-width: 100% !important;
                    font-size: 12px !important;
                }

                .md-datepicker-expand-triangle {
                    border-left: 4px solid transparent;
                    border-right: 4px solid transparent;
                    border-top: 4px solid;
                }
            }
        }

        .md-input-messages-animation {
            .md-input-message-animation {
                opacity: 1;
                margin-top: 6px !important;
                margin-left: 34px !important;
            }
        }
    }
}

#view-contacts_filter {
    display: none;
}

#view-companies_filter {
    display: none;
}

#example_filter {
    display: none;
}

.material-icons.md-18 {
    font-size: 18px;
}

.material-icons.md-24 {
    font-size: 24px;
}

.material-icons.md-36 {
    font-size: 36px;
}

.material-icons.md-48 {
    font-size: 48px;
}

.material-icons.amber600 {
    color: #FFB300;
}

.award-interpretation-error-message-text {
    margin-left: 3px;
    vertical-align: bottom;
}

.award-interpretation-error-message-icon {
    padding-bottom: 2px;
}

.award-interpreter-output-item-column-icon {
    padding-bottom: 5px;
}

.award-interpreter-output-item-error-status {
    top: 6px;
    position: relative;
}

.notification-not-sent {
    background-color: #f7f7f7;
}
.notification-queued {
    background-color: #fff176;
}
.notification-failed {
    background-color: #ffc83d;
}
.notification-viewed {
    background-color: #db77ff;
}
.notification-not-viewed {
    background-color: #88eeeb;
}
.notification-rejected {
    background-color: #ff4271;
}
.notification-accepted {
    background-color: #00d488;
}
.notification-waiting-for-reply {
    background-color: #db77ff;
}

.expirydate-invalid-message {
    margin-left:0%;
    margin-top: -3%;
    color:rgb(213,0,0);
    font-size: 12px;
    line-height: 14px;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
    opacity: 1;
}
