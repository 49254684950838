.integration__tabs {
    display: grid;
    grid-gap: 10px;
    position: relative;
    grid-template-columns: auto auto;
    justify-content: start;

    &>.tab {
        position: relative;
        margin-left: 30px;
        padding: 15px;
        bottom: 0;
        text-decoration: none !important;
        text-transform: uppercase;

        &:hover {
            color: #3b4e57;
            border-bottom: 2px solid #3b4e57;
        }

        &--selected {
            font-weight: bold;
            border-bottom: 2px solid #3b4e57;
        }
    }
}