.messaging {

    .big-screen {
        min-height : 800px;
    }

    .filters-area-big {
         @extend .md-whiteframe-3dp;
         @extend .layout-margin;
         @extend .layout-padding;
         height : 200px;

        .icon-area {
            @extend .blue-800-fg;
            @extend .icon;
            @extend .icon-format-color-fill;
        }

        .header-text {
            left : 5px;
            top : 1px;
            position : relative;
            font-weight : bold
         }
     }

    .filter {
        cursor : pointer;
        &:hover {
            cursor : pointer;
        }
    }

    .header-text {
        top : -5px;
        left : 5px;
        position :
        relative;
        font-weight : bold
    }

    .md-caption{
        font-size : 10px !important;
    }
}
