.back-to-top {

	position: fixed;
	bottom: 200px;
	right: 400px;
	z-index: 1;
	width: 50px;
	height: 50px;
	background: #000;
	opacity: 0.5;
	display: none;

	&:hover {
		opacity: 1;
		cursor: pointer;
	}

	i {
		font-size: 25px;
		padding: 12px;
		color: #FFF;
	}

}