#toolbar {
  height     : $toolbarHeight;
  min-height : $toolbarHeight;
  max-height : $toolbarHeight;
  z-index    : 55 !important;

  #navigation-toggle {
    width         : $toolbarHeight;
    min-width     : $toolbarHeight;
    height        : $toolbarHeight;
    margin        : 0;
    border-right  : 1px solid rgba(0, 0, 0, 0.12);
    border-radius : 0;
    background-color: #282c36;
    color: #FFF;

      md-icon {
          color: #FFF;
      };
  }

  #user-menu {
    height  : $toolbarHeight;
    padding : 0;
    background-color: #282c36;
    color: #FFF;

    &.md-open {
        color: #333;
    }

    md-menu-item .md-button {
      font-size : 15px;

      md-icon {
        margin : auto 16px auto 0;
      }
    }

    md-menu-content {

      &.md-menu-bar-menu {

        &.md-dense {
          padding : 8px 0;

          .md-button,
          md-menu-item {
            height      : 48px;
            line-height : 48px;
          }
        }
      }
    }

    #user-status-menu {

      md-menu-item {

        &.selected {
          opacity : .3;
        }
      }
    }

    .user-button {
      margin         : 0;
      padding        : 0 16px;
      height         : $toolbarHeight;
      text-transform : none;


      .avatar-wrapper {
        position : relative;

        .status {
          position : absolute;
          bottom   : -3px;
          right    : 5px;
        }
      }

      .username {
        margin : 0 12px 0 8px;
      }

      md-icon {
        color: #FFF
      }
    }
  }

  #language-menu {
    height : $toolbarHeight;

    .language-button {
      min-width     : $toolbarHeight;
      height        : $toolbarHeight;
      margin        : 0;
      padding       : 0 16px;
      border-radius : 0;

      .iso {
        text-transform : uppercase;
      }

      .flag {
        margin-right : 8px;
      }
    }
  }

  #quick-panel-toggle, #helpButton, #rnButton {
    width         : $toolbarHeight + 5;
    //width         : auto;
    height        : $toolbarHeight;
    margin        : 0;
    border-radius : 0;
    padding       : 8px;
    white-space: normal;
    line-height: 15px;
    > span {
      width: inherit;
    }
    background-color: #282c36;
  }

  .highlightButton {
      background: #ff0000;
      color:#fff;
}

  .toolbar-separator {
    height     : $toolbarHeight;
    width      : 1px;
    background : rgba(0, 0, 0, 0.12);
  }

   .material-icons.md-inactive {
       color: #FFF;
   }
   .material-icons.md-active {
       color: red;
   }
    .notification-badge {
        color: white;
        background-color: gray;
        margin: 0 -.8em;
        border-radius: 50%;
        padding: 1px 4px;
    }
}

#language-menu-content {

  md-menu-item {

    .flag {
      margin-right : 16px;
    }
  }
}

#language-message {
  min-height : 88px;
  max-width  : 600px;

  .md-toast-content {
    height : 90px;
  }
}

.user-nav {
    .material-icons {
        position: absolute;
        padding: 0;
        width: 24px;
        top: 6px;
        left: 24px;
        color: rgba(0,0,0,0.54);
    }
}

// RESPONSIVE
@media screen and (max-width : $layout-breakpoint-xs) {
  #toolbar {
    #user-menu {
      .user-button {
        min-width : 64px;
        padding   : 0 2px 0 10px;
      }
    }

    #navigation-toggle {
        width: fit-content;
        min-width: 0;
    }
  }
}
