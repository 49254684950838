ul[dnd-list] {
    min-height: 42px;
    padding-left: 0px;
}

ul[dnd-list] .dndDraggingSource {
    display: none;
}

ul[dnd-list] .dndPlaceholder {
    background-color: #ddd;
    display: block;
    min-height: 42px;
}

ul[dnd-list] li {
    background-color: #fff;
    border: 1px solid #ddd;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    display: block;
    padding: 10px 15px;
    margin-bottom: 1px;
    cursor: move;
}

ul[dnd-list] li.selected {
    background-color: #dff0d8;
    color: #3c763d;
}
