.document-table {
    td {
        word-break: break-word;
    }
}

.document-manager {
    min-width: 600px;
    .icon-google-drive {
        width: 250px;
    }
}

table.dataTable {
    width: 100% !important;
}

table.dataTable th {
    padding: 10px;
}

table.dataTable tbody td {
    padding: 10px;
    word-break: break-all;
}

.upload-icon {
    font-size: 40px;
}

.box-drop-file-area {
    height: 200px;
    width: 100%;
    border: 2px dashed rgb(177, 177, 177);
    position: relative;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.drop-file-area {
    margin-top: 25px;
    font-weight: 100;
    color: rgb(177, 177, 177);
}

.select-file-button {
    margin: -6px auto;
    box-shadow: 0 0px;
    color: rgb(255, 255, 255);
    background-color: rgb(69, 90, 100)
}

.drives {
    display: flex;
    justify-content: space-evenly;
}

.upload-from-cloud-drive {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.upload-from-web {
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
}

.upload-from-web__link-section {
    display: flex;
    align-self: center;
    min-width: 395px;
}

.upload-from-web__name-section {
    align-self: center;
    min-width: 395px;
}

.upload-from-web__link-section-button {
    min-height: 25px;
    max-height: 40px;
}
