#draggable-allocate-resource{
  margin-left:15px;

  .list {
    height : 100%;
  }

  .valid-allocate{
    position:relative;
    margin-top : -70px;
    margin-bottom :10px;
  }

  .xl {
    position : relative;
    left : 55px;
    margin-top : -10px;
    bottom : 10px;
  }

  .view-all{
     padding-bottom : 0px;
     min-height : 100px;
     margin-bottom : -30px;
     margin-top : -7px;
  }

  .view-less{
    padding-bottom : 0px;
    min-height : 40px;
    margin-top : -5px;
  }

  .lg {
    position : relative;
    left : 55px;
    top : -35px;
    bottom : 10px;
  }

  .list-item{
    margin : 2px;
  }

  .list-image{
    margin-top:15px;
  }

  .no-style{
    list-style-type: none;
    padding-right:5px;
    margin-left:-35px;
  }
  .divider {
    border-top-width: 3px;
    margin-top:20px;
  }
  .assetsSearch {
    margin-bottom: -30px;
  }
  ._md-subheader-content {
    text-align: center;
    border: 1px solid #cccccc;
    //border-radius: 5px;
    padding: 10px 1px 10px 1px;
    background: #a4bacf
  }
  .resource-toolbar{
    background-color: rgb(0, 111, 222);
    //border-radius: 5px 5px 0 0;
  }
  .equipment-list-scroll {
    height: 100%;
    display: block;
    position: relative;
    font-size: 14px;
  }
  .people-list-scroll {
    height: 150px;
    display: block;
    position: relative;
    font-size: 14px;
  }
  .menu-title{
    text-align: center;
    font-size: 16px;
  }

  .md-toolbar-tools{
    font-size: medium;
    height:40px;
    background-color: #E1E1E1;
    height: 40px;
    color: #4E4E4E;
    box-shadow: 0 1px 0 0px #999999
  }

  .md-subheader ._md-subheader-inner{
    padding: 10px 1px 10px 1px;
  }

  .md-subheader .md-subheader-inner {
    display: block;
    padding: 7px;
    margin-left: 10px;
  }

  .md-subheader.md-default-theme, .md-subheader {
    color: rgba(0,0,0,0.54);
    background-color: #eee;
    box-shadow: 0 1px 0 0px #e5e5ee;
  }

  .selected{
    background-color: #b2d5f8;
  }

  .draggables{
    position: relative; top : 50%;left : 40%;
  }

  .null{
    position: relative; top : 50%;left : 40%;
  }
}
