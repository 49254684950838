#manage-paperwork {
    .sidenav {
        padding-top: 15px;
        min-width: 285px;
        max-width: 350px;
        font-size: 1.2rem;
        min-height: 100%;

        form {
            font-size: 1.2rem;
        }
    }

    .center .search-row .search {
        height: 56px;
        line-height: 56px;
        padding: 18px;
        background: #FFFFFF;
    }

    .center .search-row .search .icon,
    .center .search-row .search .messaging .filters-area-big .icon-area,
    .messaging .filters-area-big .center .search-row .search .icon-area {
        margin: 0;
    }

    .center .search-row .search input {
        padding: 20px 40px;
        height: 56px;
        color: rgba(0, 0, 0, 0.54);
    }

    .center .search-row .search-wrapper .sidenav-toggle {
        margin: 0;
        width: 56px;
        height: 56px;
        background: #FFFFFF;
        border-radius: 0;
        border-right: 1px solid rgba(0, 0, 0, 0.12);
    }

    .btn-show-result {
        width: 100%;
        margin: 0;
    }

    .btn-clear {
        width: 100%;
        margin: 0;
        background-color: #0000001f;
        color: #000000;
    }

    .regenerate-filter-dates {
        padding-top: 15px;
        padding-left: 5px;
        padding-bottom: 30px;

        div {
            justify-content: flex-end;
        }
    }

    .regenerate-filter-label-date {
        right: 45px;
    }

    ._md-datepicker-floating-label > md-datepicker .md-datepicker-button {
        float: left;
        margin-top: -12px;
        margin-left: -10px;
        top: 9.5px;
    }

    ._md-datepicker-has-triangle-icon {
        padding-right: 0px;
        margin-right: 0px;
    }

    .md-datepicker-triangle-button {
        margin-right: 10px;
    }

    .md-icon-button + .md-datepicker-input-container {
        margin-left: 0px;
    }

    .input-container-checkbox {
        padding: 10px 10px;
    }

    .search-row {
        padding: 15px 15px;
    }

    .search-input {
        padding: 20px 40px;
    }

    .search {
        width: 100%;
    }

    .search md-icon{
        position: absolute;
        margin-left: 10px;
    }

    .main-content {
        padding: 0px 15px;
    }

    .table-layout {
        width: 100%;
    }

    table.dataTable thead th .table-header .column-title {
        display: unset;
        font-weight: 600;
        white-space: nowrap;
        line-height: 15px;
    }

    table.dataTable thead tr {
        background-color: #f2f0f2;
    }

    table.dataTable thead th {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    table.dataTable tbody td {
        padding: 0px 8px;
        vertical-align: middle;
    }

    .td-checkbox {
        margin-top: 15px;
        margin-left: 15px;
    }

    .action-button {
        margin: 0;
        padding: 0;
        width: 30px;
    }

    .content-header {
        padding: 23px 20px 0px;
        height: 65px;
    }

    .btn-download-selected {
        position: absolute;
        right: 250px;
        top: -15px;
        padding-left: 13px;
        padding-right: 13px;
    }

    .btn-regenerate-paperwork {
        position: absolute;
        right: 20px;
        top: -15px;
        padding-left: 13px;
        padding-right: 13px;
    }

    .select-all-checkbox {
        margin-top: 0px;
        margin-bottom: 0px;
        margin-left: 3px;
        padding-bottom: 0px;
    }

    .label-result-count {
        display: block;
        height: 16px;
        padding-left: 2px;
        font-size: 16px;
        font-weight: bold;
        margin-left: 3px;
    }

    .tags-column {
        width: 130px;
        min-width: 130px;
        max-width: 150px;
    }

    .label-paperwork-generate-status {
        padding: 3px 10px;
        border-radius: 5px;
        color: white;
    }

    .label-paperwork-generate-status.regenerating {
        background-color: #129280;
    }

    .label-paperwork-generate-status.failed{
        background-color: #ff2475;
    }

    .label-paperwork-generate-status.queued{
        background-color: #ffaf4c;
    }

    .icon-disabled {
        color: #b7b7b7;
    }

    .dataTables_filter, .dataTables_info #DataTables_Table_0_filter{
        display: none;
    }

    .dataTables_paginate {
        margin-top: 15px;
        margin-right: 30px;
    }
}
