#job-messaging {
    .content {
        overflow: hidden;
    }

    .main-checkbox {
        margin-left: 5px;
        margin-top: 16px;
    }

    &.page-layout.carded.left-sidenav > .center .content-card .toolbar {
        min-height: 64px;
        max-height: none;
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
    }

    .smart-scheduling-jobs-not-included-note {
        font-weight: 500;
    }
}
