@mixin header() {
    &>div:first-child {
        @extend h3;
        padding-bottom: 10px;
    }
}

@mixin section() {
    @include header();
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.integration__settings {
    display: grid;
    grid-gap: 10px;
    grid-template-rows: auto auto auto auto;

    &-connectors {
        @include section();

        &>div:nth-child(2) {
            display: flex;
        }
    }

    &-payroll {
        @include section();

        &>div:nth-child(2) {
            display: flex;
            padding-left: 28px;
            flex-direction: column;


            &>md-select {
                max-width: 300px;
            }
        }
    }

    &-invoice {
        @include section();

        &>div:nth-child(2) {
            padding-left: 28px;
        }
    }

    &>div:last-child {
        margin-top: 10px;

        >span {
            color: #ff3366;
            font-size: 14px;
        }

        >button {
            margin-left: -2px;
        }
    }
}