/* List card is a mixin because we want this to be re-usable */
@mixin list-card {
    position: relative;
    width: 100%;
    margin: 16px 0;
    background-color: white;
    color: #000;
    border-radius: 2px;
    transition: box-shadow 150ms ease;
    cursor: pointer;

    &.ui-sortable-helper {
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
    }

      i.s16 {
        line-height: 17px !important;
    }

    .list-card-sort-handle {
        display: none;
        position: absolute;
        top: 0;
        right: 0;
        padding: 4px;
        background: rgba(255, 255, 255, 0.8);
    }

    .list-card-details-status {
        padding: 0;

         .list-card-label2 {
                height: 18px;
                /*border-radius: 4px;*/
                //margin: 0 6px 6px 0;
         }
    }

    .list-card-details {
        padding: 16px 16px 0 16px;

        .list-card-labels {
            margin-bottom: 6px;

            .list-card-label {
                width: 32px;
                height: 6px;
                border-radius: 6px;
                margin: 0 6px 6px 0;
            }
        }

        .list-card-name {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 12px;
            height: 35px;
        }

        .list-card-address {
            min-height: 40px;
            .address-line {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        .list-card-badges {
            margin-bottom: 12px;

            .small-font {
                font-size: 0.9em;
            }

            .badge {
                margin-right: 8px;
                padding: 4px 8px;
                border-radius: 2px;
                background-color: rgba(0, 0, 0, 0.4);
                color: #FFFFFF;

                i {
                    margin-right: 4px;
                }

                &.due-date {
                    background-color: material-color('green');

                    &.overdue {
                        background-color: material-color('red');
                    }
                }

                &.ticket-date {
                    background-color: material-color('blue');
                }

                &.check-items {

                    &.completed {
                        background-color: material-color('green');
                    }
                }

                &.ticket-count {
                    background-color: material-color('blue');
                }
            }
        }


        .list-card-members {
            margin-bottom: 12px;

            .list-card-member {
                margin-right: 8px;

                .list-card-member-avatar {
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    .list-card-resources {
        padding-left: 0;
        .list-card-resource-item {
            color: rgba(0, 0, 0, 0.66);
        }
    }

    .list-card-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        padding: 8px 16px 8px 16px;

        .list-card-footer-item {
            height: 100%;
            color: rgba(0, 0, 0, 0.66);

            .value {
                padding-left: 8px;
            }
        }

        &.list-card-footer-weekly {
           padding: 8px;
        }
    }

}

#board {
    position: relative;
    //position: absolute; // Orig
    //top: 100px;  // Orig
    //right: 0; // Orig
    //bottom: 0; // Orig
    //left: 0; // Orig
    height: 100%;
    // min-height: 700px;
    // max-height: 800px;
    background: #E5E7E8;
    padding: 24px;
    /*overflow-y: hidden;
    overflow-x: auto;*/
    -webkit-overflow-scrolling: touch;


    .list-wrapper {
        width: 235px;
        min-width: 235px;
        max-width: 235px;
        padding-right: 10px;
        //padding-right: 24px;
        height: 100%;

        &.ui-sortable-helper .list {
            box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        }

        .list {
            max-height: 100%;
            background-color: #EEF0F2;
            color: #000;
            border-radius: 2px;
            transition: box-shadow 150ms ease;

            .list-header {
                height: 64px;
                min-height: 64px;
                padding: 0 0 0 16px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);

                .list-header-name {
                    max-width: 80%;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    font-size: 15px;
                    font-weight: 500;
                }

                // .list-header-option-button {

                // }
            }

            .list-content {
                position: relative;
                overflow: hidden;
                overflow-y: auto;
                min-height: 0;

                .list-cards {
                    position: relative;
                    min-height: 32px;
                    padding: 0 16px;
                    overflow-y: hidden;

                    .card-sortable-placeholder {
                        background: rgba(0, 0, 0, 0.06);
                    }

                    > div {
                        transition: none !important;
                    }
                }
            }

            .list-footer {
                display: flex;
                flex-direction: column;
                flex: 1 0 auto;
                min-height: 48px;
            }

        }
    }

    .list-sortable-placeholder {
        background: rgba(0, 0, 0, 0.06);
        margin-right: 24px;
    }

    .new-list-wrapper {
        width: 344px;
        min-width: 344px;
        max-width: 344px;
        padding-right: 24px;

        .new-list {
            border-radius: 2px;
            background-color: #EEF0F2;

            .editable-wrap {
                display: flex;
                align-items: center;
                height: 64px;
                padding: 0 4px 0 12px;

                .editable-controls {
                    width: 100%;

                    .editable-input {
                        flex: 1;
                    }
                }
            }

            .new-list-form-button {
                text-transform: none;
                font-size: 15px;
                height: 64px;
                padding: 0 16px;
                margin: 0;
                width: 100%;

                i {
                    border-radius: 50%;
                    height: 40px;
                    width: 40px;
                    line-height: 40px;
                    margin-right: 16px;
                }
            }
        }
    }

    .list-card {
        @include list-card;
    }

    /**
    * Ticket Details for week view (Virtual Repeat Implementation)
    */
    .divStyle-right-pane {
        text-align: right;
        display : inline-block;
        width: 70%;
    };

     .divStyle-left-pane {
        text-align: left;
        display: inline-block;
        margin-left: 8px;
        width: 23%;
    };

    .ticket-box, .empty-ticket-box, .last-ticket-box {
        width: 206px;
        min-width: 206px;
        max-width: 206px;
        margin-left: 10px;
        margin-right: 19px;
        height: 100%;
    }

    .last-ticket-box {
        margin-right: 0 !important;
    }


    .empty-ticket-box {
        background-color: #EEF0F2;
    }

    /**
    /* Ticket Info
    */
    .week-ticket-info-container-big {
        height: auto;
    }

    .week-ticket-info-container-small {
        height: 180px;
    }

    /**
    * Ticket footer
    */
    .footer-container {
        min-width: 280px;
    }

    #vertical-container {
        height: 775px;
        //width: 1652px;
        width: 98%;
        position: absolute;
        top: 85px;
        margin-top: 5px;
    }

    .repeated-item {
        //border-bottom: 1px solid yellowgreen;
        box-sizing: border-box;
        height: 290px;
        //padding-top: 10px;
        margin-bottom: 20px;
    }

    .daily-ticket-info-container {
        height: 175px !important;
    }

} // Job board

.is-mobile {

    #board {

        .list-card {

            .list-card-sort-handle {
                display: block;
            }
        }
    }
}

.list-card-sort-helper {
    @include list-card;
}


    /**
    * Panel context menu
    * Popup menu (Angular implem)
    */
    .wb-panel-menu {
        background: white;
        border-radius: 4px;
        box-shadow: 0 7px 8px -4px rgba(0, 0, 0, 0.2),
            0 13px 19px 2px rgba(0, 0, 0, 0.14),
            0 5px 24px 4px rgba(0, 0, 0, 0.12);
        width: 256px;
      }

      .wb-panel-menu-item {
        align-items: center;
        cursor: pointer;
        display: flex;
        height: 48px;
        padding: 0 16px;
        position: relative;
        transition: background 0.15s linear;
        width: auto;
      }

      .wb-panel-menu-item:hover,
      .wb-panel-menu-item:focus {
        background-color: rgb(238, 238, 238);
      }

      .wb-panel-menu-item.selected {
        color: rgb(16, 108, 200);
      }


      #vertical-container {
        //height: 800px;
        width: 100%;
    }



