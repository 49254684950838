#allocate-resource {

    .toolbar {
        .toolbar-wrapper {
            button {
                margin-right: 5px;
                padding-left: 10px;
                padding-right: 10px;
            }

            &.device-sm {
                .action-button {
                    margin-right: -15px;

                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }

    .md-scroll-mask {
        position: initial !important;
    }

    md-backdrop.md-menu-backdrop {
        position: initial !important;
        z-index: 99;
    }

    md-menu {
        md-backdrop.md-menu-backdrop {
            position: initial !important;
        }

        .md-scroll-mask {
            position: initial !important;
        }
    }

    .main-content {
        background-color: white;
        position: relative;
    }

    .first-button-margin {
        margin: 6px 3px 6px 6px;
    }

    .button-margin {
        margin: 6px 3px 6px 3px;
    }

    .content {

        .card {
            background: #FFFFFF;
            margin: 16px auto 48px auto;
            padding: 24px;
            width: 800px;

            p {
                font-size: 17px;
                line-height: 30px;
            }

            .selected {
                background: #b2d5f8;
            }
        }
    }

    #smartSchedulingFailedSection md-icon {
        margin: 11px 5px 0px 17px;
    }

    .md-cancel span {
        color: red;
    }

    #smartSchedulingFailedSection .message {
        color: red;
    }

    .md-retry span {
        color: black;
    }

    .legend-deep-purple-circle {
        border-radius: 50%;
        background-color: #673AB7 !important;
        height: 20px;
        width: 20px;
        margin: 0 5px 0 5px;
    }

    .preschedule-legend-container {
        padding-bottom: 0;
    }
}

.shift-tags-dialog {

    md-chip {
        font-size: 15px;

        &.tag-inactive {
            background-color: #f6f6f6;
            color: #8f9196;
            border: 1px solid #d5d5d6;

            md-icon {
                color: #8f9196; 
            }
        }

        md-icon {
            min-width: 20px;
            min-height: 20px;
        }
    }
}