.textArea {

    .counter{
        top : -15px;
        position : relative;
    }

    .text-zone {
        background-color: #E0E0E0;
        font-size : 15px;
    }

}
