#comments-dialog {
    .comments-dialog-toolbar {
        .search {
            background: white;
            padding: 2px 20px;
            font-size: 0.6em;

            .search-input {
                margin-bottom: 12px;
                padding-right: 24px;
            }
        }
    }
    .comments-dialog-content {
        padding-top: 2px;
        height: 420px !important;
        .list-item {
            padding: none !important;
            .item {
                .msg {
                    padding-left: 20px;
                    padding-right: 32px;
                    // white-space: -moz-pre-wrap !important;      /* Mozilla, since 1999 */
                    // white-space: -webkit-pre-wrap !important;   /* Chrome & Safari */
                    // white-space: -pre-wrap !important;          /* Opera 4-6 */
                    // white-space: -o-pre-wrap !important;        /* Opera 7 */
                    // white-space: pre-wrap !important;           /* css-3 */
                    // word-wrap: break-word !important;           /* Internet Explorer 5.5+ */
                    // word-break: break-all !important;
                    white-space: normal;                        /* Fall back */
                    text-align: justify;
                    text-justify: inter-word;
                    &.removed {
                        font-style: italic;
                    }
                }
                .item-menu {
                    position: absolute;
                    top: 12px;
                    right: 0;
                }
                .datestamp {
                    // padding-right: 32px;
                    text-align: right;
                    font-style: italic;
                }
            }
        }
    }
    .comments-dialog-actions {
        .input {
            &.msg {
                margin-right: 8px;
            }
            margin: 8px 0;
            .msg-entry {
                // overflow: auto !important;
                padding-right: 24px;
                // height: 108px;
                // max-height: 108px;
                resize: none;
            }
        }
    }
}
