#resource-sidenav-options {
    height : 50px;
    max-height : 50px;
    background-color: #E1E1E1;
    display: flex;

    .actions {
        i {
            cursor : pointer;
        }
    }
}
