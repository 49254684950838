#message-area-contacts{

    .chips-section{
        top : 30px;
        position : relative;
    }

    .icon-color {
        color : #475A64;
    }

    .autocomplete-icon {
        margin : 10px;
    }

}
