.rules-list{

  background-color : #fafafa;
  min-height : 800px;

  .label {
    margin-left : 2px;
    font-size : 13px;
    background-color: #4097f6;
    color : white;
    padding : 3px;
  }


}
