#droppable-resource {

  #equipment-dropzone{
    height:300px;
    margin-bottom:7px;
  }

  #people-dropzone{
    height:300px;
    margin-top:7px;
  }

  .resource-toolbar{
    background-color: #E1E1E1;
    height: 40px;

    .md-toolbar-tools {
      font-size: medium;
      height: 40px;
      background-color: #E1E1E1;
      height: 40px;
      color: #4E4E4E;
      box-shadow: 0 1px 0 0px #999999
    }
  }
}

