#job-messaging {
    .top-bg2 {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        left: 0;
        height: 150px;
        background-image: url('/assets/images/backgrounds/header-bg.png');
        background-size: cover;
    }
    .content {
       padding: 0;
    }
    // Center
    .center {
        .header {
            padding: 24px 0;
            height: 86px;
            min-height: 86px;
            max-height: 86px;
            color: #FFFFFF;
        .sidenav-toggle {
                margin: 0;
                width: 56px;
                height: 56px;
                background: #FFFFFF;
                border-radius: 0;
                border-right: 1px solid rgba(0, 0, 0, 0.12);
            }
            .search {
                height: 56px;
                line-height: 56px;
                padding: 18px;
                background: #FFFFFF;
                .icon {
                    margin: 0;
                }
                input {
                    padding: 20px 40px;
                    height: 56px;
                    color: rgba(0, 0, 0, 0.54);
                }
            }
        }
    }
    .status {
        font-size: 12px;
        white-space: nowrap;
        padding: 2px 5px;
        border-radius: 2px;
    }
    .sub-titles {
        color: rgba(0, 0, 0, 0.54);
    }
    .invoice-detail {
        cursor: pointer;
    }
    .invoice-resource-table {
        margin-top: 0 !important;
        margin-bottom: 30px;
        thead {
            tr {
                th {
                    padding: 0 !important;
                }
            }
        }
    }
    .invoice-table {
        margin-top: 16pt;
        thead {
            margin-bottom: 5px;
            tr {
                th {
                    font-size: 10pt;
                    max-width: 60pt;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    border-bottom: 0;
                    padding: 5px 8px;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
            tr:nth-child(odd) {
                background-color: #f4f4f4;
            }
            tr:nth-child(even) {
                background-color: #fff;
            }
        }
        /* Put line spacing before body */
        tbody:before {
            content: "-";
            display: block;
            line-height: 1em;
            color: transparent;
        }
        .title {
            font-size: 10pt;
        }
        .detail {
            margin-top: 4pt;
            font-size: 9pt;
            max-width: none;
        }
        .qty-col {
            width: 100px;
        }
        .unit-price-col {
            width: 120px;
        }
        .amt-col {
            width: 130px;
        }
        .start-time-col {
            width: 180px;
        }
        .end-time-col {
            width: 180px;
        }
        .hrs-col {
            width: 180px;
        }
    }
    .invoice-table-footer {
        margin: 32px 0 0 0;
        tr {
            td {
                text-align: right;
                font-size: 17px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.54);
                border-bottom: none;
                padding: 8px 8px;
                &:first-child {
                    text-align: left;
                }
            }
            &.discount {
                td {
                    padding-bottom: 32px;
                }
            }
            &.total {
                td {
                    padding: 32px 8px;
                    border-top: 1px solid rgba(0, 0, 0, 0.12);
                    font-size: 24px;
                    font-weight: 300;
                    color: rgba(0, 0, 0, 1);
                }
            }
        }
    }

    .message-sent {
        opacity : 0.5;
        background-color : #f9f9f9;
    }

    .message-unsent {
        opacity : 1;
    }
}
