.dynamic-grid {
    .chk {
        margin-top: 5px;
        margin-left : 5px;
        margin-bottom : 5px;
    }

    .table-main{
        width: 100%;
        min-width: 100%;

        td {
            margin:0px;
            padding : 0px;
        }

        .time-box {
            @extend .md-light-blue-800-bg;
            border-radius: 2px;
            padding: 4px 8px;
            margin: 0 8px;
            font-size: 11px;
            font-weight: 500;
            white-space: nowrap;
        }

        .pure-material-checkbox {
            position: relative;
            left : 10px;
            display: inline-block;
            color: rgba(0, 0, 0, 0.87);
            cursor: pointer;
            font-family: "Roboto", "Segoe UI", BlinkMacSystemFont, system-ui, -apple-system;
            font-size: 14px;
            line-height: 18px;
        }

        .pure-material-checkbox > input {
            appearance: none;
            -moz-appearance: none;
            -webkit-appearance: none;
            position: absolute;
            z-index: -1;
            left: -15px;
            top: -15px;
            display: block;
            margin: 0;
            border-radius: 50%;
            width: 48px;
            height: 48px;
            background-color: rgba(0, 0, 0, 0.42);
            outline: none;
            opacity: 0;
            transform: scale(1);
            -ms-transform: scale(0); /* Graceful degradation for IE */
            transition: opacity 0.5s, transform 0.5s;
        }

        .pure-material-checkbox > input:checked {
            background-color: #505D65;
        }

        .pure-material-checkbox:active > input {
            opacity: 1;
            transform: scale(0);
            transition: opacity 0s, transform 0s;
        }

        .pure-material-checkbox > input:disabled {
            opacity: 0;
        }

        .pure-material-checkbox > input:disabled + span {
            color: rgba(0, 0, 0, 0.38);
            cursor: initial;
        }

        .pure-material-checkbox > span::before {
            content: "";
            display: inline-block;
            margin-right: 15px;
            border: solid 2px rgba(0, 0, 0, 0.42);
            border-radius: 2px;
            width: 14px;
            height: 14px;
            vertical-align: -4px;
            transition: border-color 0.5s, background-color 0.5s;
        }

        .pure-material-checkbox > input:checked + span::before {
            border-color: #505D65;
            background-color: #505D65;
        }

        .pure-material-checkbox > input:active + span::before {
            border-color: #505D65;
        }

        .pure-material-checkbox > input:checked:active + span::before {
            border-color: transparent;
            background-color: rgba(0, 0, 0, 0.42);
        }

        .pure-material-checkbox > input:disabled + span::before {
            border-color: rgba(0, 0, 0, 0.26);
        }

        .pure-material-checkbox > input:checked:disabled + span::before {
            border-color: transparent;
            background-color: rgba(0, 0, 0, 0.26);
        }

        .pure-material-checkbox > span::after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 10px;
            border: solid 2px transparent;
            border-left: none;
            border-top: none;
            transform: translate(5.5px, 1px) rotate(45deg);
            -ms-transform: translate(5.5px, 2px) rotate(45deg);
        }

        .pure-material-checkbox > input:checked + span::after {
            border-color: #fff;
        }
    }

}
