#invoice-report {

    .toolbar-content-with-progress {
        margin-top: -5px;
    }
    
    .toolbar-content {
        margin-top: 0;
    }

    .center {

        .content-card {

            &.classic {

                .toolbar {
                    position: relative;
                    padding: 8px 24px;

                    .select-menu {

                        .md-icon-button {
                            padding-left: 0;
                            margin: 0;
                        }
                    }

                    .page-info {
                        font-weight: 500;
                        margin: 0 8px;
                        white-space: nowrap;
                    }

                    .close-thread {
                        margin: 0 0 0 -8px;
                    }
                }

                .content-wrapper {
                    position: relative;
                }
            }
        }
    }

    .rounded-pill {
        border-radius: 20px; 
        border: 2px solid #BC05FF;
        color:#BC05FF;
        font-size: 9pt;
        padding: 3px 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin: 2px 4px;
    }

    .rounded-pill-gray {
        border-radius: 20px; 
        border: 2px solid gray;
        color:gray;
        font-size: 9pt;
        padding: 3px 5px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        margin: 2px 4px;
    }

    .pull-right{
        float: right;
    }
}

.thread-labels-toggle-list {

    md-list-item {

        md-icon {
            margin: 0 !important;
        }
    }    
}