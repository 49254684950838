#awards-view {

    .card {
        @extend .md-whiteframe-1dp;
        @extend .white-bg;
        @extend .mr-20;
        @extend .mb-20;
        border-radius: 8px;

        .card-row {
            @extend .m-10;
        }

        hr {
            border: 1px solid #e6e6e6;
            border-top: transparent;
        }

        .card-full-button {
            @extend .pt-8;
            @extend .pb-8;
            @extend .mb-10;
            @extend .text-uppercase;
            @extend .bold-text;
            @extend .layout-align-center-center;
            @extend .layout-row;
            cursor: pointer;
            border-radius: 5px;
            border: 1px solid #e6e6e6;

            .button-text {
                @extend .font-size-12;
                @extend .pl-10;
            }
        }
    }
}
