a8-header {
    height: stretch;
    background-color: #282c36;
    color: #FFF;

    .a8-header {
        position: relative;

        .header-text {
            height: stretch;
            font-weight: 300;
            min-height: 64px;

            span {
                padding: 0 0.5em;
            }
        }

        .text-common {
            float: right;
            font-weight: 500;
            color: #BC05FF;
        }
    }
}


// MOBILE
@media screen and (max-width : $layout-breakpoint-xs) {
    a8-header {
        .header-text {
            font-size: 0.7em;
            font-weight: 600;

            .page-title {
                margin-left: 10px;
            }

            .text-common {
                margin-right: 10px;
            }
        }
    }
}

// IPAD
@media screen and (min-width: $layout-breakpoint-xs) and (max-width : $layout-breakpoint-sm) {
    a8-header {
        .header-text {
            font-size: 0.8em;
            font-weight: normal;

            span {
                vertical-align: -webkit-baseline-middle;
            }
        }
    }
}

// WEB
@media screen and (min-width : $layout-breakpoint-sm) {
    a8-header {
        .header-text {
            font-size: 1.2em;
            font-weight: normal;

            span {
                vertical-align: middle;
            }
        }
    }
}
