.timesheet-edit-dialog {
    .label-control{
        margin-bottom: 2px;
    }

    .flat-button{
        box-shadow: 0 0px;
        color: #000;
        background-color: #fff;
    }

    .parent-collection{

        .form-radio
        {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
            display: inline-block;
            position: relative;
            background-color: #f1f1f1;
            color: #fff;
            top: 10px;
            height: 30px;
            width: 30px;
            border: 0;
            border-radius: 50px;
            cursor: pointer;
            margin-right: 7px;
            outline: none;
        }
        .form-radio:checked::before
        {
            position: absolute;
            font: 13px/1 'Open Sans', sans-serif;
            left: 11px;
            top: 7px;
            content: '\02143';
            transform: rotate(40deg);
        }
        .form-radio:hover
        {
            background-color: #f7f7f7;
        }
        .form-radio:checked
        {
            background-color: #ff880d;
        }

        .radio-collection {
            min-width: max-content;
            padding: 1em;
        }

        .warning-message{
            color: #ff880d;
        }
    }

    .edit-reason-section {
        .dialog-textarea-no{
            border-radius: 1rem;
            border: solid 1px;
            min-width: 100%;
            height: 150px;
            resize: none;
            padding: 13px;
        }

        .dialog-textarea-yes{
            border-radius: 1rem;
            border: 1px solid;
            min-width: 100%;
            resize: none;
            padding: 13px;
            height: 45px;
            overflow: hidden;
        }

        h3 {
            float: left;
            width: 100%;
            margin: 0;
        }
        .character-count{
            display: block;
            width: 100%;
            text-align: right;
            padding: 5px;
            color: #a8a8a8;
            font-size: 12px;
        }
    }
}
