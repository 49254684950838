.manage-comment-restrictions {
    @keyframes added {
        from {
            opacity: 0;
            transform: rotateX(-90deg);
            transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
        }
        to {
            opacity: 1;
            transform: none;
            transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
            perspective: 100px;
        }
    }

    @keyframes deleted {
        from {
            opacity: 1;
            transform: none;
            transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
            perspective: 100px;
        }
        to {
            opacity: 0;
            transform: rotateX(-90deg);
            transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
        }
    }

    .task-item.ng-enter {
        animation: 0.5s linear added;
    }

    .task-item.ng-leave {
        animation: 0.5s linear deleted;
    }

    .side-not-selected {
        max-width : 4px;
        position : relative;
        background-color: #0b93d5;
    }

    .saving-blur {
        opacity: 0.5;
    }
}
