#vertical-navigation {
  width: $navigationWidth;
  min-width: $navigationWidth;
  max-width: $navigationWidth;
  font-size: 1.2rem;
  overflow-x: wrap;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2), width 0.1s linear, min-width 0.1s linear, max-width 0.1s linear;
  box-shadow: $whiteframe-shadow-6dp;
  height: 100%;
  background-color: rgb(45, 50, 62) !important;

  .scrollable-60 {
    height: 60%;
  }
  .scrollable-40 {
    height: 40%;
  }

  .navigation-header {
    height: 64px;
    min-height: 64px;
    background-color: #282c36;
    padding: 0 16px 0 24px;

    .logo-image {
      display: block;
      max-height: 40px;
      max-width: 107px;
    }

    .logo-image-small {
      display: block;
      max-height: 40px;
      max-width: 60px;
    }

    .fold-toggle {
      transition: transform 0.3s ease-in-out 0.1s;
      transform: rotate(0deg);
      margin: 0;
      padding: 8px;
      width: 34px !important;
      height: 34px !important;
      cursor: pointer;
      color: #fff;
    }
  }

  &:not(.md-locked-open) {
    box-shadow: $whiteframe-shadow-8dp;
  }

  ms-nav ms-nav-item .ms-nav-button {
    padding: 0 5px 0 24px;
  }

  .quick-links-nav {
    height: auto;
    margin: 1em;

    .quick-links-title {
        display: block;
        width: 100%;
        height: 10px;
        border-bottom: 1px solid #FFF;
        text-align: center;

        .quick-links-divider {
            font-size: 15px;
            background-color: rgb(45,50,62);
            padding: 0 10px;
            color: #FFF;
        }
    }

    .quick-links-items {
      padding: 0.5em 0;
      .link-item {
          &:hover {
              background: rgba(0, 0, 0, 0.12);
          }
          cursor: pointer;
          padding: 0.7em 24px;
          text-align: left;
          a {
              color:  rgba(255, 255, 255, 0.70);;
              &:hover {
                  text-decoration: none;
              }
              .icon-history {
                  color: rgba(255, 255, 255, 0.7);
                  margin: 0 13px 0 0;
                  vertical-align: middle;
              }
              span {
                  vertical-align: middle;
              }
          }
      }
    }

    hr {
      margin-bottom: -1em;
    }
  }

  .app-version-number {
      bottom: 0;
      position: absolute;
  }
}

// Folded navigation
@media only screen and (min-width: $layout-breakpoint-sm) {

  .ms-navigation-folded {

    #content-container {
      margin-left: $navigationFoldedWidth;
    }

    #vertical-navigation {
      position: absolute;

      .navigation-header {

        .fold-toggle {
          transform: rotate(180deg);
          opacity: 0;
        }
      }
    }

    &.ms-navigation-folded-open {

      #vertical-navigation {

        .navigation-header {

          .fold-toggle {
            transition: transform 0.3s ease-in-out 0.1s, opacity 0.3s ease-in-out 0.2s;
            opacity: 1;
          }
        }
      }
    }

    &:not(.ms-navigation-folded-open) {

      #vertical-navigation {
        position: absolute;
        width: $navigationFoldedWidth;
        min-width: $navigationFoldedWidth;
        max-width: $navigationFoldedWidth;

        .navigation-header {
          padding: 0 16px;

          .logo-image {
            width: 28px;
            margin-left: 4px;
          }
        }

        .quick-links-nav {
            .quick-links-divider {
                display: none;
            }

            .ms-nav-label {
                //padding-left: 5px;
                padding-right: 5px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
      }
    }
  }

}
