#invoice-report {

    .notification-bar {
        background-color: #ffc534;
        border-radius: 6px;
        border: none;
    }

    .invoicing-company {
        @extend .white-bg;
        padding: 20px;
        margin: 10px 0;
        border: 1px solid #e6e6e6;
        border-radius: 6px;

        md-icon {
            margin-right: 10px;
        }

        .job-queued {
            color: #FFB600;
        }

        .job-generated {
            color: #00d488;
        }

        .job-failed {
            color: #ff4271;
        }

        .badge {
            @extend .bold-text;
            @extend .md-caption;
            background-color: #e6e6e6;
            padding: 8px 12px;
            border-radius: 20px;
            border: 1px solid #e6e6e6;
        }
    }

    .top-bg2 {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        left: 0;
        height: 150px;
        background-image: url('/assets/images/backgrounds/header-bg.png');
        background-size: cover;
    }

    .react-linear-activity {
        width: 99%;
        position: relative;
        top: 10px;
    }

    .header {

        .sidenav-toggle {
            margin: 0;
            width: 56px;
            height: 56px;
            background: #FFFFFF;
            border-radius: 0;
            border-right: 1px solid rgba(0, 0, 0, 0.12);
        }

        .search {
            padding: 12px;

            .icon {
                margin: 0;
            }

            input {
                margin-left: 12px;
                color: rgba(0, 0, 0, 0.54);
                border-bottom: 1px solid rgb(230, 230, 230);
                height: 36px;
                padding-left: 16px;
            }
        }
    }

    .status {
        font-size: 12px;
        white-space: nowrap;
        padding: 2px 5px;
        border-radius: 2px;
    }

    .sub-titles {
        color: rgba(0, 0, 0, 0.54);
    }

    .invoice-detail {
        cursor: pointer;
    }

    .invoice-resource-table {
        margin-top: 0 !important;
        margin-bottom: 30px;

        thead {
            tr {
                th {
                    padding: 0 !important;
                }
            }
        }
    }

    .invoice-table {

        thead {
            tr {
                th {
                    font-size: 12px;
                    max-width: 60pt;
                    text-transform: uppercase;

                    &:first-child {
                        padding-left: 0;
                    }

                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    border-bottom: 0;
                    padding: 4px 3px;
                }
            }

            tr:nth-child(odd) {
                background-color: #f9f9f9;
            }

            tr:nth-child(even) {
                background-color: #fff;
            }
        }

        /* Put line spacing before body */
        tbody:before {
            content: "-";
            display: block;
            line-height: 1em;
            color: transparent;
        }

        .title {
            font-size: 10pt;
        }

        .detail {
            margin-top: 4pt;
            font-size: 9pt;
            max-width: none;
        }

        .qty-col {
            width: 100px;
        }

        .unit-price-col {
            width: 120px;
        }

        .amt-col {
            width: 130px;
        }

        .start-time-col {
            width: 180px;
        }

        .end-time-col {
            width: 180px;
        }

        .hrs-col {
            width: 180px;
        }
    }

    .invoice-table-footer {
        margin: 20px 0 0 0;

        tr {
            td {
                text-align: right;
                font-size: 16px;
                border-bottom: none;
                padding: 8px 4px;

                &:first-child {
                    text-align: left;
                }
            }

            &.discount {
                td {
                    padding-bottom: 32px;
                }
            }

            &.total {
                td {
                    padding: 12px 4px;
                    font-size: 20px;
                    font-weight: 500;
                }
            }
        }
    }
}
