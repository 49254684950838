#edit-cost-estimate-item {
    overflow: hidden;
    .desc-col {
        width: 400px;
    }
    .qty-col {
        width: 20px;
    }
    .editable-table {
        .input-control {
            width: 100% !important;
        }
        .inline-error {
            position: relative !important;
            left: 0 !important;
            top: 0;
            width: 100% !important;
        }
        input[type="text"] {
            padding: 5px;
            height: 30px;
            position: relative;
            left: 0;
            outline: none;
            border: 1px solid #cdcdcd;
            border-color: rgba(0, 0, 0, .15);
            background-color: white;
            // font-size: 14px;
        }
        input[type="text"]:focus {
            -webkit-box-shadow: none;
            box-shadow: none;
        }
        thead {
            tr:hover {
                background-color: #fff !important;
            }
        }
    }
}