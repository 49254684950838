.message-dialog {

    i {
        color : #475A64;
    }

    .icon-not-added {
        color : #475A64;
    }

    .icon-added {
        color : #50A042;
    }

    .add-ticket {
        margin-top : -15px;
        margin-bottom : 10px;

        .ticket-icon {
            color : #475A64;
            font-size : 20px;
            cursor : pointer;
        }

        .ticket-link {
            cursor : pointer;
            margin-top : 10px;
        }
    }

    .add-person {
        color : #475A64;
        cursor: pointer;
    }

    .company {
        margin-top : -12px;
    }

    .status {
        width : auto;
        font-size: 12px;
        white-space: nowrap;
        padding: 2px 5px;
        border-radius: 2px;
        text-align: left;
        display: inline-block;
        color : white;
        background-color: #0b93d5;
    }

}
