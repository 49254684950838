#add-calendar {
    background: #FFFFFF;

    .content {
        padding: 20px;

        .date-row {
            md-datepicker {
                margin-right: 24px;
                margin-top: 17px;
            }
        }

        .all-day{
            width: 200px;
        }

        md-autocomplete-wrap > input[type="search"]{
            padding: 0 !important;
        }

        .md-chip-input-container{
            width: 350px;
        }

        md-input-container {
                .demo-fieldset{
                    div {
                        .md-label{
                            margin-left:25px;
                            margin-right:5px;
                        }
                        .demo-select-all-checkboxes{
                            max-width: 100%;
                            margin-right: 12px;
                        }
                    }
                }
            }
    }
}

@media only screen
and (min-device-width: 960px) {
    .add-calendar-select-container {
        left: 244px !important;
    }

}
