#award-classification {

    .dataTable thead tr {
        background-color: #323F4B;
    }

    .column-title {
        font-weight: 400;
        color: white;
    }

    .dtr-title .column-title {
        font-weight: 500;
        color: inherit;
    }
}
