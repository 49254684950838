#invoice-pdf {
    .content {
        margin-top: 5px;
        padding-top: 20px;
        .form-wrapper2 {
            background: #FFFFFF;
            padding: 16px;
            .form-title {
                font-size: 21px;
                padding: 8px 0;
            }
            .md-button {
                margin-left: 0;
            }
        }
    }
    .toolbar-custom {
        background-color: #FFFFFF;
    }
    .toolbar-wrapper {
        margin-left: 15px;
    }
    .label {
                display: inline-block;
                font-size: 11px;
                padding: 0 5px;
                margin-right: 6px;
                color: #FFFFFF;
                font-weight: bolder;
                min-width: 50px;
                text-align: center;

                &:first-child {
                    margin-left: 0;
                }
                &.invoice {
                    background-color: material-color('green');
                }
                &.credit {
                    background-color: material-color('red');
                }
    }

    .pending-action {
        color: #FFB600;
    }
}