#ticket-number {
    padding : 8px;

    .ticket-section {
        padding : 8px;
    }

    .display-none {
        display: none !important;
    }
}
