.jobs-whiteboard-filter-container {
    .jobs-whiteboard-filter-item {
        padding-right: 20px;

        &.text-search-filter {
            input {
                color :#000;
                font-weight: 500;
            }

            label {
                color: #000;
                font-weight: 500;
            }
        }

        .md-select-value {
            color: #000000 !important;
            font-weight: 500;
        }

        md-input-container {
            margin: 10px 0;
        }

        .filter-placeholder {
            color: #000000;
            font-weight: 500;

            .filter-counter {
                margin-left: 0.5em;
                font-size: 0.8em;
                color: #f7f7f7;
                width: 1.5em;
                height: 1.4em;
                padding: 0.1em;
                border-radius: 1em;
                display: inline-block;
                text-align: center;
                font-weight: 300;
            }
        }

        &.filter-fields .filter-placeholder .filter-counter {
            background: #4a23cd;
        }

        &.filter-ticketTypes .filter-placeholder .filter-counter {
            background: #1c8176;
        }

        &.filter-branches .filter-placeholder .filter-counter {
            background: #e700b8;
        }

        &.filter-states .filter-placeholder .filter-counter {
            background: #25a935;
        }

        &.filter-timeofday .filter-placeholder .filter-counter {
            background: #ff8300;
        }

        &.filter-include-tags .filter-placeholder .filter-counter {
            background: #bc05ff;
        }

        &.filter-exclude-tags .filter-placeholder .filter-counter {
            background: #3986c7;
        }

        &.filter-timesheet-submission-types .filter-placeholder .filter-counter {
            background: #6b7f0f;
        }

        &.filter-ticket-status .filter-placeholder .filter-counter {
            background: #6e66c2;
        }
        .md-select-icon {
            width: auto;
        }
    }

    md-chips {
        box-shadow: none;
        padding-bottom: 0;

        .md-chips {
            box-shadow: none;
        }

        md-chip {
            background: transparent;
            font-size: 0.7em;
            margin-right: 1.5em;
            margin-bottom: 1em;

            .md-chip-content {
                height: 100%;
                line-height: 28px;
            }

            .md-chip-remove-container {
                height: 100%;
            }

            .md-chip-remove {
                height: 100%;

                &:hover {
                    background: transparent !important;
                }

                md-icon {
                    height: 1em;
                    width: 1em;
                    min-height: 0;
                    min-width: 0;
                }
            }
        }
    }

    .chips-fields {
        md-chip {
            color: #4a23cd;
            border: 2px solid #4a23cd;

            .md-chip-remove {
                md-icon {
                    color: #4a23cd !important;
                }
            }
        }
    }

    .chips-ticketTypes {
        md-chip {
            color: #1c8176;
            border: 2px solid #1c8176;

            .md-chip-remove {
                md-icon {
                    color: #1c8176 !important;
                }
            }
        }
    }

    .chips-branches {
        md-chip {
            color: #e700b8;
            border: 2px solid #e700b8;

            .md-chip-remove {
                md-icon {
                    color: #e700b8 !important;
                }
            }
        }
    }

    .chips-states {
        md-chip {
            color: #25a935;
            border: 2px solid #25a935;

            .md-chip-remove {
                md-icon {
                    color: #25a935 !important;
                }
            }
        }
    }

    .chips-timeofday {
        md-chip {
            color: #ff8300;
            border: 2px solid #ff8300;

            .md-chip-remove {
                md-icon {
                    color: #ff8300 !important;
                }
            }
        }
    }

    .chips-include-tags {
        md-chip {
            color: #bc05ff;
            border: 2px solid #bc05ff;

            .md-chip-remove {
                md-icon {
                    color: #bc05ff !important;
                }
            }
        }
    }

    .chips-exclude-tags {
        md-chip {
            color: #3986c7;
            border: 2px solid #3986c7;

            .md-chip-remove {
                md-icon {
                    color: #3986c7 !important;
                }
            }
        }
    }

    .chips-timesheet-submission-types {
        md-chip {
            color: #6b7f0f;
            border: 2px solid #6b7f0f;

            .md-chip-remove {
                md-icon {
                    color: #6b7f0f !important;
                }
            }
        }
    }

    .chips-ticket-status {
        md-chip {
            color: #6e66c2;
            border: 2px solid #6e66c2;

            .md-chip-remove {
                md-icon {
                    color: #6e66c2 !important;
                }
            }
        }
    }

    .select-apply-button {
        color: #ff8300;
    }
}

.tags-filter-dropdown-whiteboard {
    margin-top: 6.5em;
}
