#manage-item {

    .header {
        position: relative;

        .header-content {
            height: 100%;
        }
    }

    [scroll] {
        position: fixed;
        top: 0;
    }

    .center {
        overflow: hidden !important;

        .content {
            margin-top: 0;
            padding-top: 20px;
            padding-bottom: 0;
            background-color: inherit;

            // Form override
            .form-wrapper2 {
                background: #FFFFFF;
                padding: 16px;

                .form-title {
                    font-size: 21px;
                    padding: 8px 0;
                }

                .md-button {
                    margin-left: 0;
                }
            }

            // For grids
            td {
                padding-top: 3px;
                padding-bottom: 3px;
            }

            .scroll-container {
                position: relative;
                height: 250px;

                strong {
                    color: #878787;
                }
            }

        }

        // Toolbar fixed
        .toolbar {
            background: #FFFFFF !important;
            border-color: rgba(0, 0, 0, 0.12);
            display: block;
            position: relative;

            &-wrapper {
                //height: 56px;
                position: relative;
                overflow: hidden;
                display: block;

                .item {
                    display: inline-block;
                    margin-right: 24px;
                }
            }
        }

        .stock-qty {
            font-weight: bolder;
            color: #1d1d1d;
        }

    }

    #categoryTree {
        ul {
            list-style-type: none;
        }
    }

    .hire-rate-table {
        min-width: 350px;
    }

    .forms-manager {
        margin-top: 20px;
    }
}