.ratecard-manage {
    .create-btn-holder {
        .create-ratecard-btn {
            margin-top: -1em;
            position: absolute;
            right: 0;
        }
    }
}

.ratecard-create {
    .ratecard-multiple-select .md-select-value span:first-child {
        visibility: collapse;
    }

    .ratecard-multiple-select .md-select-value .md-text {
        display: inline-block !important;
        visibility: visible;
    }

    .ratecard-multiple-select .md-select-value .md-text:not(:last-child):after {
        content: ', \00a0 '; /* star */
        margin: 0 -5px 0 5px;
    }
}
.order-preferences
{
    cursor: pointer;
    margin-left: 2px;
}
