#resource-list {
    .box-design {
        border-radius: 2px;
        padding: 2px 4px;
        font-size: 9px;
        font-weight: 400;
        white-space: nowrap;
    }

    .itm-name {
        position: relative;
        top: 4px;
    }

    .nameSection {
        cursor: pointer;
    }

    .headerInfo {
        @extend .blue-500-fg;
        border-bottom: 2px solid #3F97F6;
        margin : 0px 10px 0px 10px;
    }

    .employment {
        position : relative;
        top : -3px;

        .employment-status {
            @extend .md-blue-grey-500-bg;
            @extend .box-design;
            @extend .margin-top-10;
        }
        .vehicleArea {
            position:relative; top:3px; min-width : 30px;
            .vechicle {
                font-size : 19px;
            }
        }
    }

    .additionaDetails {
        position : relative;
        top : -20px;
    }

    .action-button {
        cursor: pointer;
    }

    .darkred {
        color : darkred;
    }

    .steelblue {
        color : steelblue;
    }
}
