#company-create {

  .code {
    margin-bottom: 1rem;
  }

  .header {
    position: relative;
    .header-content {
      height: 100%;
    }
  }

  [scroll] {
    position: fixed;
    top: 0;
  }

  .center {
    overflow: hidden !important;

    .content {
      margin-top: 0;
      padding-top: 20px;
      padding-bottom: 0;
      background-color: inherit;

      .card {
        background: #FFFFFF;
        margin: 16px auto 48px auto;
        padding: 24px;

        p {
          font-size: 17px;
          line-height: 30px;
        }
      }

      .form-wrapper-2 {
        background: #FFFFFF;
        padding: 10px;
      }

      .form-wrapper {
        margin-top: 5px;
        margin-bottom: 24px;
      }
    }
  }

  // Toolbar fixed
  .toolbar {
    background: #FFFFFF !important;
    border-color: rgba(0, 0, 0, 0.12);
    display: block;
    position: relative;

    &-wrapper {
      //height: 56px;
      position: relative;
      overflow: hidden;
      display: block;

      .item {
        display: inline-block;
        margin-right: 24px;
      }
    }
  }
}

input[type="file"].image-input {
  display: none;
}

label.image-input {
  cursor: pointer;
  i {
    opacity: 0;
    transition: opacity 300ms ease-out;
    -o-transition: opacity 300ms ease-out;
    -moz-transition: opacity 300ms ease-out;
    -webkit-transition: opacity 300ms ease-out;
    position: absolute;
    margin-top: 30px;
    margin-left: 30px;
  }

  img {
    //margin: 0 50px;
    opacity: 1;
    transition: opacity 300ms ease-out;
    -o-transition: opacity 300ms ease-out;
    -moz-transition: opacity 300ms ease-out;
    -webkit-transition: opacity 300ms ease-out;
    &:hover {
      opacity: .5;
      transition: opacity 300ms ease-out;
      -o-transition: opacity 300ms ease-out;
      -moz-transition: opacity 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out;
    }
  }

  &:hover {
    i {
      opacity: 1;
      transition: opacity 300ms ease-out;
      -o-transition: opacity 300ms ease-out;
      -moz-transition: opacity 300ms ease-out;
      -webkit-transition: opacity 300ms ease-out;
    }
  }
}

#company, #custom {
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }

  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }

  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }

  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    width: 100%;
  }

  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {
    width: 100%;
  }

  @media screen and (min-width: 960px){
    width:100%;
  }

  @media screen and (min-width: 1024px)
  and (max-device-width: 1136px){
    width:90%;
  }

  @media screen and (min-width: 1280px)
  {
    width:80%;
  }

/*

  @media screen and (min-width: 1920px){
    width:70%;
  }*/
}

#myobCompanies-dialog, #xeroCompanies-dialog {

  .instruction-warning,
  .instruction-warning label {
      color: #ff8100;
  }
}

.hint {
  position: absolute;
  left: 5px;
  right: auto;
  top: 4rem;
  font-size: 12px;
  line-height: 14px;
  color: rgba(0, 0, 0, 0.54); }