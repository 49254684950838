#cost-estimate-preview {
    .toolbar-content-with-progress {
        margin-top: -5px;
    }
    .toolbar-content {
        margin-top: 0;
    }
    .center {
        .content-card {
            &.classic {
                .toolbar {
                    position: relative;
                    padding: 8px 24px;
                    .select-menu {
                        .md-icon-button {
                            padding-left: 0;
                            margin: 0;
                        }
                    }
                    .page-info {
                        font-weight: 500;
                        margin: 0 8px;
                        white-space: nowrap;
                    }
                    .close-thread {
                        margin: 0 0 0 -8px;
                    }
                }
                .content-wrapper {
                    position: relative;
                }
            }
        }
    }
}

.thread-labels-toggle-list {
    md-list-item {
        md-icon {
            margin: 0 !important;
        }
    }
}