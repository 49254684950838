.resource-messaging {
    @extend #job-messaging;

    .status-default {
        font-size: 12px;
        white-space: nowrap;
        padding: 2px 6px;
        max-width: 200px !important;
        border-radius: 2px;
        text-align: center;
        display: inline-block;
    }
}
