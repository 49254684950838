.resource-dialog {
    .ps-scrollbar-x {
        display: none !important;
    }

    .locked-note {
        color: red;
        padding-top: 6px;
        font-size: 12px;
    }
    .info-note {
        color: orange;
        padding-top: 6px;
        font-size: 12px;
    }
}
