.rounded-pill {
    border-radius:20px;
    border: 2px solid #BC05FF;
    color:#BC05FF;
    font-size: 9pt;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 5px 8px;

    .delete {
        cursor: pointer;
    }

    &.inactive {
        border-color: #929292;
        color: #929292;
    }
}

.tags-autocomplete {
    display: block;
    margin-top: 4px;
    md-autocomplete {
        z-index: 2;
        md-input-container{
            &:after {
                display: block;
                content: '\25BC';
                position: absolute;
                top: 0.5em;
                right: 0;
                opacity: 0.7;
                speak: none;
                font-size: 13px;
                transform: scaleY(0.5) scaleX(1);
            }
        }
    }
}

.tags-autocomplete .md-autocomplete-suggestions-container li {
    border-bottom: 1px solid #ccc;
    height: auto;
    padding: 3px 0 3px 8px;
    margin-right: 3px;
    margin-left: 3px;
    white-space: normal;
}

.tags-autocomplete .md-autocomplete-suggestions-container li:last-child  {
    border-bottom-width: 0;
}

.job-time-error {
    font-size: 12px;
    position: absolute;
    color: red;
    width: auto;
    top: 40px;
    left: 0;
    white-space: pre;
}

.job-time-warning {
    font-size: 12px;
    position: absolute;
    color: #ff8100;
    width: auto;
    top: 40px;
    left: 0;
    white-space: pre;
}