#contact-create-company {

  .column-left {
    margin-right: 24px;

    md-autocomplete {
      width: 100%;
    }
  }

  .column-right {
  }

  .contact-add-icon{
    position : relative;
    top : 30px;
    left : 5px;
  }

}


