#recipient-logs {
    
    .recipients-logs-table {
        min-width: 500px;
    }

    .md-inline-form md-checkbox {
        margin: 5px 20px 18px;
    }

    .react-linear-activity-custom {
        width: 50px;
    }

    .labels {
        background: #FFFFFF;
        //text-align: center;
        padding-top: 5px;
        
        .label {
            display: inline-block;
            font-size: 11px;
            padding: 0 5px;
            //margin-right: 6px;
            font-weight: bold;
            color: #FFFFFF;
            
            &:first-child {
                margin-left: 0;
            }

            &.sent {
                background-color: material-color('green');
            }
            &.unsent {
                background-color: material-color('orange');
            }
            &.queueing {
                background-color: material-color('blue');
            }
            &.failed {
                background-color: material-color('red');
            }
        }
}
}