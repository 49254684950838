#header-with-search {
  .search-wrapper {
    box-shadow: none;
  }
  .search {
    position : relative;
    //margin-right : 20px;
    height: 56px;
    line-height: 56px;
    padding: 18px;
    background: #FFFFFF;
    .icon {
      margin: 0;
      color: rgba(0, 0, 0, 0.54);
    }
    input {
      padding-left: 16px;
      height: 56px;
      color: rgba(0, 0, 0, 0.54);
    }
  }
}
