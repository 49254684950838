#droppable-resource {
    #equipment-dropzone {
        height: 275px;
        margin-bottom: 7px;
    }

    #equipment-dropzone.full {
        margin-bottom: 0;
    }

    #people-dropzone {
        height: 275px;
        margin-top: 7px;
    }

    .resource-toolbar {
        background-color: #E1E1E1;
        height: 40px;
    }
}
