#droppable-allocate-resource-xl {
    .equipment-dropzone {
        height: 300px;
        margin-bottom: 7px;
    }

    .equipment-dropzone-full {
        height: 750px;
        margin-bottom: 7px;
    }

    #option-dropzone {
        height: auto;
        margin-top: 7px;
        margin-bottom: 10px;
    }

    #people-dropzone {
        height: 450px;
        margin-top: 7px;
    }

    .resource-toolbar {
        background-color: #E1E1E1;
        height: 40px;
    }

    .md-toolbar-tools {
        font-size: medium;
        height: 40px;
        background-color: #475A68;
        height: 40px;
        color: white;
        box-shadow: 0 1px 0 0px #999999
    }

    .md-subheader ._md-subheader-inner {
        padding: 10px 1px 10px 1px;
    }

    .md-subheader .md-subheader-inner {
        display: block;
        padding: 7px;
        margin-left: 10px;
    }

    .md-subheader,
    .md-subheader.md-default-theme {
        color: rgba(0, 0, 0, 0.54);
        background-color: #eee;
        box-shadow: 0 1px 0 0px #e5e5ee;
    }

    .selected {
        background-color: #bacddb;
    }

    .droppable-spinner {
        position: relative;
        margin: 0 50%;
        top: 24px;
    }

    .table-position {
        position: relative;
    }

    .table-md-checkbox {
        border-spacing: 0;
        position: relative;
        margin-top: -15px;
        margin-bottom: -15px;
    }
    
    .deep-purple-label{
        color: #673AB7 !important
    }

    .shift-add-tags-button{
        color: #6ec3ba !important;
    }
}
