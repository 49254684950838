  #job-timesheet {

      .toolbar-wrapper button {
          margin-right: 5px;
          padding-left: 10px;
          padding-right: 10px;
      }

      .header-icon {
          margin-top: -3px;
      }

      .icon-column {
          width: 25px;
          padding: 0;
      }

      tr:first-child td {
          padding-top: 10px;
      }

      tr.validation-section {
          &:hover {
              background: #FFFFFF;
          }

          &td {
              padding-bottom: 6px !important;
          }
      }

      td {
          padding: 0px 8px;
      }

      .content {
          .card {
              background: #FFFFFF;
              margin: 16px auto 48px auto;
              padding: 24px;
              width: 800px;

              p {
                  font-size: 17px;
                  line-height: 30px;
              }

              .selected {
                  background: #b2d5f8;
              }
          }

          .has-error {
              border-color: rgb(255, 0, 0);
              border-bottom-width: 2px;
          }
      }

      .has-validation {
          color: rgb(255, 115, 22)
      }

      .paperwork-comments {
          margin: auto;
      }

      .timesheet-edit-reason {
          display: block;
          font-size: 1.3em;
          margin-bottom: .6em;
          margin-left: 0;
          margin-right: 0;
          width: 95%;
          word-break: break-word;
      }

      .timesheet-edit-date {
          display: block;
          font-size: 1em;
          margin-bottom: .4em;
          margin-left: 0;
          margin-right: 0;
      }

      .timesheet-edit-gps-coordinate {
        padding-left: 0px;
      }

      .gps-coordinates-title {
        padding-bottom: 0px;
        margin-bottom: 0px;
        padding-top: 0px;
      }

      .gps-coordinates-values {
        padding-top: 0;
        padding-bottom: 0;
      }

      .show-google-map-button{
        width: 105px;
        height: 37px;
        padding: 0px;
        text-transform: none;
        font-weight: normal;
      }
  }
