$bp-desktop: 1024px;
$bp-tablet: 768px;
$bp-mobile: 576px;

#form-builder {
    margin-bottom: 20px;

    .cf-form-builder-preview {
        .cf-editor {
            border: 2px solid #d8d8d8;
            background-color: #fff;
        }

        .cf-section:hover {
            cursor: default;
        }
    }

    .cf-form-builder {
        border: none;
        background: none;
        font-family: inherit;
    }

    .cf-editor {
        border: 2px dashed #d8d8d8;
        padding: 20px;
        border-radius: 8px;
        background-color: #f7f7f7;
        margin-right: 15px;
    }

    .cf-component-templates {
        padding-top: 0;
    }

    .pdf-preview-body {
        overflow: hidden;
        height: 100%;

        iframe {
            border: none;
            height: 50vh;
        }
    }

    .cf-button {
        @extend .form-builder-button;
        color: white;
        text-transform: capitalize;
        background-color: #0671ec;
        line-height: 24px;
        box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
        padding: 7px 14px;
    }

    .cf-table {

        table,
        th,
        td {
            padding: 5px;
        }
    }

    .cf-input {
        padding: 8px 5px;
        border: 1px solid rgb(204, 204, 204);
        border-radius: 4px;
    }

    .cf-component-control {
        @extend .form-builder-button;
        color: white;
        text-transform: capitalize;
        background-color: #0671ec;
        font-size: 12px;
        min-width: 60px;
    }

    .form-builder-button {
        @extend .md-button;
        @extend .md-raised;
        @extend .md-accent;
        @extend .md-mini;
        margin: 5px;
        border-radius: 4px;
        font-weight: 400;
    }

    .cf-vehicle-canvas,
    .cf-signature-pad {
        height: 200px !important;
    }

    .cf-component-templates-header {
        font-size: 16px;
        color: #ffffff;
        background-color: #2D323E;
        margin-bottom: 12px;
        font-weight: 400;
        padding: 10px 0px;
    }

    .cf-component-templates-group {
        border: none;
    }

    .cf-component {
        font-size: 13px;
        padding: 2px;

        label {
            margin-bottom: 3px;
        }
    }

    .cf-form-header {
        @extend .cf-section;
    }

    .cf-section {
        border-radius: 4px;
        border-color: rgb(204, 204, 204);
        margin: 6px 0;
    }

    .cf-config-section {
        margin: 8px 0;
        border-radius: 4px;
        font-size: 13px;
    }

    .cf-config-controls {
        margin-bottom: 4px;
    }

    .cf-config-delete-option,
    .cf-config-delete-condition {
        padding: 0;
        margin: 0;
        min-height: 30px;
        min-width: 45px;
    }

    .cf-component-condition {
        display: grid;
        grid-template-columns: 60px 300px 20px 300px 60px;
        grid-gap: 5px;
        align-items: center;
    }

    .cf-component:hover .cf-component-controls {
        border: none;
        margin: 0;
    }

    .cf-component-controls {
        min-width: 66px;
    }

    .cf-alert {
        color: #ff0000;
    }

    .cf-input-char-limit {
        float: right;
        font-size: 0.9em;
        color: #808080;
    }

    .cf-options-comma-warning {
        float: right;
        font-size: 0.9em;
        color:#ff880d;
    }

    

}
