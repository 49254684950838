#award-allowances {

    .dataTable thead tr {
        background-color: #323F4B;
    }

    .column-title {
        font-weight: 400;
        color: white;
    }

    .dtr-title .column-title {
        font-weight: 500;
        color: inherit;
    }

    md-chips {
        md-chips-wrap {
            box-shadow: 0 0px !important;
            padding: 0;
        }

        md-chip {
            @extend .font-size-10;
            height: 22px;
            line-height: 22px;
            background-color: white;
            padding: 0 8px;

            &.shift-allowance {
                color: #3986c7;
                border: 1px solid #3986c7;
            }

            &.automatic-allowance {
                color: #bc05ff;
                border: 1px solid #bc05ff;
            }
        }
    }
}
