#jobFormsDataTable {

    tr.form-incomplete {
        color: rgb(170, 170, 170);
    }

    table.dataTable {
        width: 100% !important;
    }

    table.dataTable thead th {
        padding: 10px;
    }

    table.dataTable tbody td {
        padding: 10px;
        word-break: break-all;
    }

    .dataTables_wrapper {
        height: 100%;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .dataTables_info {
        padding-left: 5px;
        padding-bottom: 5px;
        flex-grow: 1;
        top: 40px;
        width: 50%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .dataTables_paginate {
        width: 100%;
        text-align: right;
        padding-bottom: 5px;
    }

    .dataTables_info,
    .dataTables_paginate {
        margin: 0px;
    }

    .submittedAtLocation{
        text-decoration: underline;
        color: rgba(61,145,240,255);
    }
}
