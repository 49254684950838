.resource-messaging {
    @extend #job-messaging;

    //0 - Notification not yet Sent
    .status-0 {
        @extend .person-status;
        @extend .notification-not-sent;
        font-size: 11px;
        width: auto;
    }

    //1 - SMS Queued
    .status-1 {
        @extend .person-status;
        @extend .notification-queued;
        font-size: 11px;
    }

    //2 - Failed to Send
    .status-2 {
        @extend .person-status;
        @extend .notification-failed;
        font-size: 11px;
    }

    //3 - Notification Sent (Viewed)
    .status-3 {
        @extend .person-status;
        @extend .notification-viewed;
        font-size: 11px;
    }

    //4 - Notification Sent (Not Viewed)
    .status-4 {
        @extend .person-status;
        @extend .notification-not-viewed;
        font-size: 11px;
    }

    //5 - Sms Waiting for Reply
    .status-5 {
        @extend .person-status;
        @extend .notification-waiting-for-reply;
        font-size: 11px;
    }

    //6 - Job Rejected
    .status-6 {
        @extend .person-status;
        @extend .notification-rejected;
        font-size: 11px;
    }

    //7 - Job Accepted
    .status-7 {
        @extend .person-status;
        @extend .notification-accepted;
        font-size: 11px;
    }
}
