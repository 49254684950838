#depreciation {
    #end-date {
        .md-datepicker-button  {
            padding-left: 0;
            margin-left: 0;
        }
    }
}

#individual-item {
    .scroll-container {
        position: relative;
        height: 250px;

         strong{
           color: #878787;
         }
      }
}
