#edit-invoice-item {
    .editable-table tr:hover {
        background-color: #EEEEEE;
    }
    .moveHandle {
        cursor: move;
    }
    .action-column th {
        width: 140px;
        background-color: greenyellow;
    }
    .desc-column th {
        max-width: 20%;
    }
    .util-column {
        width: 10%;
    }
    // For grids
    td {
        padding-top: 3px;
        padding-bottom: 3px;
    }
    tbody {
        tr:nth-child(odd) {
            background-color: #f4f4f4;
        }
        tr:nth-child(even) {
            background-color: #fff;
        }
    }
}