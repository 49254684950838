.contact-allowances {

    .allowance-list{

        tr {
            &.inactive{
                color: #aaaaaa
            }
        }
        
        .inactive-allowance-tag{
            text-transform: uppercase;
        }
    }
}
