#update-payroll-contacts {
    .center {
        overflow: hidden !important;

        .content {
            margin-top: 0;
            padding-top: 24px;
            padding-bottom: 0px;
            background-color: inherit;
        }
    }

    .toolbar {
        background: #FFFFFF !important;
        border-color: rgba(0, 0, 0, 0.12);
        display: block;
        position: relative;
    }

    .dropdown-instruction {
        position: relative;
        font-size: 12px;
        margin-top: 10px;
        margin-left: 24px;
        margin-bottom: -14px;
        color: #00000061;
    }

    .instruction-warning {
        color: #ff8100;
    }

    table.dataTable thead tr {
        background-color: #f2f0f2;
    }

    .dataTables_length {
        margin-top: 26px;
        margin-left: 20px;
    }

    .dataTables_info {
        clear: none;
        margin-top: 17px;
        margin-left: 60px;
    }

    .dataTables_paginate {
        margin-top: 15px;
        margin-right: 30px;
        margin-bottom: 20px;
    }

    div.white-bg {
        margin-bottom: 20px;
    }
}