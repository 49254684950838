.tags-page {
    .text-center {
        text-align: center;
    }
    .manage-tags-table {
        tr {
            &.inactive {
                color: rgba(0, 0, 0, 0.4);
                display: table-row !important;
                td {
                    .enable-checkbox {
                        .md-icon {
                            border-color: rgba(0, 0, 0, 0.4);
                            color: rgba(0, 0, 0, 0.4);
                        }
                    }
                }
            }


            td {
                padding: 8px;

                .enable-checkbox {
                    margin-top: 0.5em ;
                    margin-bottom: 0.5em ;
                }
            }
        }
    }
}

#add-tag-dialog {
    .md-input-messages-animation {
        font-size: 0.7em;
        margin-top: 0.5em;
        color: #ff3366;
    }
}



