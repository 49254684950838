#award-settings {

    .settings-container {
        @extend .mb-10;

        .setting-item {
            @extend .white-bg;
            @extend .p-10;
            @extend .mb-5;
            border: 1px solid #e6e6e6;

            md-icon {
                @extend .mr-5;
            }
        }
    }

    .icon-active {
        color: #00d488;
    }

    .icon-inactive {
        color: #ff4271;
    }
}
