.company-details {
   .scroll-container {
      min-height: 200px;
   }
   
   .dropdown-instruction {
      position: relative;
      font-size: 12px;
      color: #00000061;
   }
   
   .instruction-warning {
      color: #ff8100;
   }
}

.provider-company-dialog {
   .loading {
      margin-left: 3px;
      margin-top: -10px;
   }
}


