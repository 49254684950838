#import-file-item {
    input[type="file"].image-input {
    display: none;
    }

    label.image-input {
     cursor: pointer;
     display: block;
   }
}
