ul.allocation-errors li {
    margin-left: -10px !important;
}
#ai-allocation{
    padding: 8px;
    .preschedule-section{
        font-size: smaller;
    }
}
.min-width-65{
    min-width: 65px !important;
    max-width: 65px !important;
}
.ai-failed-scheduling{
    color: red;
    font-size: 10px;
}
