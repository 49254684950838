// .searchbox-wrapper {}

  .searchbox-container {

    input {
      border-bottom: solid 1px rgba(0, 0, 0, 0.12) !important;
      width: 520px;
      &:focus {
        border-bottom: solid 2px rgba(0, 0, 0, 0.87) !important;
      }
    }
  }


.toggle-menu[type=checkbox] {
  display: none;
}

.toggle-menu[type=checkbox] + label.toggle-menu:before {
  cursor: pointer;
  content: "\e79c";
  font-family: 'roboto';
  display: inline-block;
}

.toggle-menu[type=checkbox]:checked + label.toggle-menu:before {
  color: $color-primary;
}
