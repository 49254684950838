  #company-card-ticket-list {

  tbody td {
      padding-top: 3px;
      padding-bottom: 3px;  
    }

  .action-column {
    text-align: center;
  }

}