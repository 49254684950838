#reports-project {
    .md-chips .md-chip-input-container {
        width: 100%;
    }
}

#report-jobs {
    .report-job-queued {
        border-bottom-color: #ffc83d !important;
    }

    .report-job-failed {
        border-bottom-color: #ff4271 !important;
    }

    .report-job-generated {
        border-bottom-color: #00d488 !important;
    }

    .current-report-job {
        border: solid 2px rgb(69,90,100);
    }

    .instruction-note {
        color: #ff8100;
     }

}
