button.toolbar-button {
    height: 35px;
    min-width: 96px;
}

.toolbar-header {
    margin: 0 1rem;
    padding: 0.2rem;
}

.label {
    margin-top: 16px;
}

.warning-message {
    color: #ff880d;
}

#job-view {

    md-checkbox {
        display: flex;
        align-items: center;
    }

    md-input-container {
        width: 100%;
        padding-right: 20px;
    }

    .header {
        position: relative;

        .header-content {
            height: 100%;
        }
    }

    .introjs-helperLayer {
        box-sizing: content-box;
        position: absolute;
        z-index: 9999998;
        border: 1px solid #777;
        border: 1px solid rgba(0, 0, 0, .5);
        border-radius: 4px;
        box-shadow: 0 2px 15px rgba(0, 0, 0, .4);
        -webkit-transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -ms-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
        transition: all 0.3s ease-out;
    }

    .action-button {
        margin: 0;
        padding: 0;
        width: 30px
    }

    .ms-responsive-table-wrapper {
        overflow-x: hidden;
    }

    .header-filter {
        margin-left: 22px;
    }

    [scroll] {
        position: fixed;
        top: 0;
    }

    .center {
        overflow: hidden !important;

        .content {
            margin-top: 0;
            padding-top: 20px;
            padding-bottom: 0;
            background-color: inherit;

            .form-wrapper-2 {
                background: #FFFFFF;
                padding: 10px;
            }

            .form-wrapper {
                margin-top: 5px;
                margin-bottom: 24px;
            }
        }
    }

    // Toolbar fixed
    .toolbar {
        background: #FFFFFF !important;
        border-color: rgba(0, 0, 0, 0.12);
        display: block;
        position: relative;

        &-wrapper {
            //height: 56px;
            position: relative;
            overflow: hidden;
            display: block;

            .item {
                display: inline-block;
                margin-right: 24px;
            }
        }

        md-input-container {
            margin-bottom: 0;
        }
    }

    .jobs-filter-container {

        .tag-filter-container {
            margin-left: 0.5rem;

            .selected-tags {
                md-input-container {
                    margin-top: 6px;
                    margin-bottom: 6px;
                }
            }

            .tag-autocomplete {
                width: 12.5%;
                margin-left: 12.5%;

                md-autocomplete {
                    z-index: 2;

                    md-input-container {
                        margin-bottom: 1em;

                        &:after {
                            display: block;
                            content: '\25BC';
                            position: absolute;
                            top: 0.5em;
                            right: 0;
                            opacity: 0.7;
                            speak: none;
                            font-size: 13px;
                            transform: scaleY(0.5) scaleX(1);
                        }
                    }
                }
            }

            .tag-chips-container {
                margin-left: -12.5%;
                padding-top: 0.8em;
                width: 70%;

                .mask {
                    position: relative;
                    float: left;
                    clear: none;
                    background-color: white;
                    width: 19%;
                    height: 34px;
                    vertical-align: middle;
                }

                md-chips {
                    box-shadow: none;
                    padding-bottom: 0;

                    .md-chips {
                        box-shadow: none;

                        &.md-removable md-chip .md-chip-content {
                            padding-right: 1.5em;
                        }
                    }

                    md-chip {
                        background: transparent;
                        color: #bc05ff;
                        border: 1px solid #bc05ff;
                        font-size: 0.7em;
                        margin-right: 1.5em;
                        margin-bottom: 1em;
                        padding: 0 1em;

                        .md-chip-remove-container {
                            right: 1.3em;
                        }

                        .md-chip-remove {
                            width: auto;

                            &:hover {
                                background: transparent !important;
                            }

                            md-icon {
                                color: #bc05ff;
                                height: 1em;
                                width: 1em;
                                min-height: 0;
                                min-width: 0;
                            }
                        }
                    }
                }
            }
        }
    }


    .view-job-icon {
        position: relative;
        top: 3px;
        margin-right: 5px;
        margin-left: 2px;
    }
}

.tags-filter-dropdown {
    margin-top: 1.5em;
}
