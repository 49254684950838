#asset-category-tree {
    // ul.manage-tree {
    //     list-style: none;
    // }

    // .angular-ui-tree {
    //     // padding: 5px;
    // }

    .angular-ui-tree-node {
         padding-right: 0;
    }


    .angular-ui-tree-empty {
        border: 0;
    }

    // .angular-ui-tree-nodes {
    //     // position: relative;
    //     // margin: 0;
    //     // padding: 0;
    //     // list-style: none;
    // }

    .angular-ui-tree-handle {
        border: 1px solid gainsboro;
        padding-left: 5px;
        padding-right: 0;
        margin-bottom: 5px;
    }

    .angular-ui-tree-placeholder {
         background-color: rgb(245,245,245);
    }

    .angular-ui-tree-hidden {
       display: none;
    }

    .collapse-expand:hover {
        cursor: pointer;
    }

}