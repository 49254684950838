.ratecard-pricing {
    animation-duration: 1s;
    animation-name: slideup;

    .ratecard-pricing-flexsheet div[wj-part='container'] {
        height: 55vh !important;
    }

    .wj-new-sheet {
        display:none !important;
    }
}

@keyframes slideup {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
