.message-section {
    i {
        color : #475A64 !important;
    }

    .send-button{
        top : -20px;
        position : relative;
    }

    .message-section{
        top : -5px;
        position : relative;

        .bg{
            background-color : whitesmoke
        }
    }
}
