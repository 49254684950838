/*
 Alloc8 Grid
*/

.a8grid {
    thead {
        th {
            &.company-name {
                width: 12%;
            }
            &.tags {
                width: 10%;
            }
        }
        tr {
            background-color: #f2f0f2;
        }
    }
}

.a8grid tr:hover {
    background-color: #E7E8E7;
}

.a8-pagination {
    border-top: solid 2px #EEEEEE;
}