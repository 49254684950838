#dashboard-project {
    .content {
        padding: 24px;
        padding-right: 32px;

        .card {
            margin: 16px auto 48px auto;
            padding: 24px;

            p {
                font-size: 17px;
                line-height: 30px;
            }
        }
    }
}

//----- Grid System
$gutter: 32px;

.container {
    width: 100%;

    .ms-widget {
        padding: 0px;
    }
}

.packery-wrapper {
    width: auto;
}

$base-h: 182px;

.packery-sizer,
.packery-object {

    width: 0px;
    height: $base-h;

    &.h-x2 {
        height: calc((#{$base-h} * 2) + (#{$gutter} * 1)) !important;

        ms-widget {
            height: calc((#{$base-h} * 2) + (#{$gutter} * 1)) !important;
        }

        .w-content {
            height: calc((#{$base-h} * 2) - 16px);
            position: relative;
        }
    }

    &.h-x3 {
        height: calc((#{$base-h} * 3) + (#{$gutter} * 2)) !important;

        ms-widget {
            height: calc((#{$base-h} * 3) + (#{$gutter} * 2)) !important;
        }

        .w-content {
            height: calc((#{$base-h} * 3) - 16px);
            position: relative;
        }
    }
}

.packery-object {
    float: left;
}

.wide {
    width: 300px;
}

.old {
    border: 1px solid red;
}

.wider {
    width: 600px;
}

.taller {
    height: 600px;
}

.orange {
    background: orange;
}

.green {
    background: green;
}

.red {
    background: red;
}

.blue {
    background: blue;
}

.card.single {
    width: 300px;
    height: 400px;
}

.single {
    background: blue;
}

.card.double {
    width: 620px;
    height: 400px;
}

.double {
    background: red;
}

.card-header {
    cursor: move;
}

%clearfix:after {
    display: block;
    clear: both;
    content: '';
}

.row {
    @extend %clearfix;
}

[class^="col-"] {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

@media only screen and (min-width: 768px) {

    $a: 12;
    $b: 6;
    $c: 4;
    $d: 3;
    $e: 2.4;
    $f: 2;
    $g: 1.714285714285714;
    $h: 1.5;
    $i: 1.333333333333333;
    $j: 1.2;
    $k: 1.090909090909091;

    .col-1 {
        width: calc((100% / #{$a}) - ((#{$gutter} * 11) / #{$a}));
    }

    .col-2 {
        width: calc((100% / #{$b}) - ((#{$gutter} * 10) / #{$a}));
    }

    .col-3 {
        width: calc((100% / #{$c}) - ((#{$gutter} * 9) / #{$a}));
    }

    .col-4 {
        width: calc((100% / #{$d}) - ((#{$gutter} * 8) / #{$a}));
    }

    .col-5 {
        width: calc((100% / #{$e}) - ((#{$gutter} * 7) / #{$a}));
    }

    .col-6 {
        width: calc((100% / #{$f}) - ((#{$gutter} * 6) / #{$a}));
    }

    .col-7 {
        width: calc((100% / #{$g}) - ((#{$gutter} * 5) / #{$a}));
    }

    .col-8 {
        width: calc((100% / #{$h}) - ((#{$gutter} * 4) / #{$a}));
    }

    .col-9 {
        width: calc((100% / #{$i}) - ((#{$gutter} * 3) / #{$a}));
    }

    .col-10 {
        width: calc((100% / #{$j}) - ((#{$gutter} * 2) / #{$a}));
    }

    .col-11 {
        width: calc((100% / #{$k}) - ((#{$gutter} * 1) / #{$a}));
    }

    .col-12 {
        width: 100%;
    }
}


/* Portrait and Landscape */

@media only screen and (min-device-width: 320px) and (max-device-width: 735px) and (-webkit-min-device-pixel-ratio: 1) {
    .col-1 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-2 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-3 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-4 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-5 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-6 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-7 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-8 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-9 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-10 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-11 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }

    .col-12 {
        max-height: 100%;
        min-width: 100%;
        margin: auto
    }
}


/* ===========================================
 Defining media queries
 =========================================== */

@mixin bp-huge {
    @media only screen and (min-width: 65em) {
        @content;
    }
}

@mixin bp-medium {
    @media only screen and (min-width: 35em) {
        @content;
    }
}

@mixin bp-small {
    @media only screen and (min-width: 25em) {
        @content;
    }
}


/* ===========================================
 Mixin for writing out breakpoint names in
 an after pseudo element
 =========================================== */

@mixin define-breakpoint($name) {
    &:after {
        content: $name;
        display: none;
    }
}


/* ===========================================
 Calling our writing-out function
 =========================================== */

body {
    @include bp-small() {
        @include define-breakpoint('bp-small');
    }

    @include bp-medium() {
        @include define-breakpoint('bp-medium');
    }

    @include bp-huge() {
        @include define-breakpoint('bp-large');
    }
}

// SPECIFIC WIDGET TYPES CSS
.bar-widget {
    height: 100%;

    .bars {
        height: 100%;

        nvd3 {
            height: 100%;
        }
    }

    .support {
        height: 100%;
    }
}

.tabular {
    border: none !important;
    border-spacing: 0 !important;
    width: stretch !important;

    tr td {
        border-bottom: 1px solid gray;
        padding: 0 16px !important;
        padding-top: 12px !important;

        &:nth-child(2) {
            text-align: end;
        }
    }
}
