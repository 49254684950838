#create-job {

    .toolbar-wrapper button {
        margin-right: 5px;
        padding-left: 10px;
        padding-right: 10px;
    }

    .dt {
        .md-datepicker-expand-triangle {
            position: relative !important;
            margin: 0 !important;
            padding: 0 !important;
            bottom: 6px !important;
        }
    }

    .header {
        position: relative;

        .header-content {
            height: 100%;
        }
    }

    .header-filter {
        padding-right: 22px !important;
    }

    [scroll] {
        position: fixed;
        top: 0;
    }

    .center {
        overflow: hidden !important;

        .content {
            margin-top: 0;
            padding-top: 20px;
            padding-bottom: 0;
            background-color: inherit;

            .ms-stepper .ms-stepper-vertical .ms-stepper-steps {
                padding: 0px;
            }

            .form-wrapper-2 {
                background: #FFFFFF;
                padding: 10px;
            }

            .form-wrapper {
                margin-top: 5px;
                margin-bottom: 24px;
            }

            .ms-stepper-steps {
                .ms-stepper-navigation-item {
                    background-color: #ececec;
                    margin-bottom: 1px;
                    box-shadow: 0 1px 0 0px #999999;
                }

                .current {
                    background-color: #999999;
                    font-size: larger;

                    .title {
                        color: #eceadf;
                        font-family: 'Roboto', sans-serif;
                    }
                }
            }

            //.md-button.md-default-theme.current, .md-button:not(current){
            //  background-color: #ECECEC;
            //}
            //md-button.md-default-theme:not([current]), .md-button:not([current]) {
            //  background-color: rgba(158, 158, 158, 0.2);
            //  border: 0.1em groove #999999;
            //}
            .ms-stepper-step-content {
                margin-top: 15px;

                ._md-datepicker-floating-label>md-datepicker .md-datepicker-button {
                    display: none;
                }

                ._md-datepicker-floating-label._md-datepicker-has-calendar-icon>label:not(.md-no-float):not(.md-container-ignore) {
                    width: calc(100% - 20px);
                }

                .md-datepicker-input-container {
                    display: block;
                    margin-left: 0 !important;
                    width: 100% !important;

                    .md-datepicker-input {
                        max-width: none;
                        font-size: 15px;
                    }
                }

                .md-toolbar-tools {
                    font-size: medium;
                    height: 40px;
                    background-color: #E1E1E1;
                    height: 40px;
                    color: #4E4E4E;
                    box-shadow: 0 1px 0 0px #999999
                }

                .md-subheader ._md-subheader-inner {
                    padding: 10px 1px 10px 1px;
                }

                .md-subheader .md-subheader-inner {
                    display: block;
                    padding: 7px;
                    margin-left: 10px;
                }

                .md-subheader.md-default-theme,
                .md-subheader {
                    color: rgba(0, 0, 0, 0.54);
                    background-color: #eee;
                    box-shadow: 0 1px 0 0px #e5e5ee;
                }

                md-select.md-default-theme[disabled] .md-select-value,
                md-select[disabled] .md-select-value {
                    color: rgba(0, 0, 0, 0.5);
                }

                md-input-container.md-default-theme .md-input[disabled],
                md-input-container .md-input[disabled],
                [disabled] md-input-container.md-default-theme .md-input,
                [disabled] md-input-container .md-input {
                    color: rgba(0, 0, 0, 0.5);
                }

                md-select.md-default-theme[disabled] .md-select-icon,
                md-select[disabled] .md-select-icon {
                    display: none;
                }

                md-checkbox.md-default-theme[disabled] .md-label,
                md-checkbox[disabled] .md-label {
                    color: rgba(0, 0, 0, 0.87);
                }
            }
        }
    }

    // Toolbar fixed
    .toolbar {
        background: #FFFFFF !important;
        border-color: rgba(0, 0, 0, 0.12);
        display: block;
        position: relative;

        &-wrapper {
            //height: 56px;
            position: relative;
            overflow: hidden;
            display: block;

            .item {
                display: inline-block;
                margin-right: 24px;
            }
        }
    }
}

input[type="file"].image-input {
    display: none;
}

label.image-input {
    cursor: pointer;

    i {
        opacity: 0;
        transition: opacity 300ms ease-out;
        -o-transition: opacity 300ms ease-out;
        -moz-transition: opacity 300ms ease-out;
        -webkit-transition: opacity 300ms ease-out;
        position: absolute;
        margin-top: 30px;
        margin-left: 30px;
    }

    img {
        //margin: 0 50px;
        opacity: 1;
        transition: opacity 300ms ease-out;
        -o-transition: opacity 300ms ease-out;
        -moz-transition: opacity 300ms ease-out;
        -webkit-transition: opacity 300ms ease-out;

        &:hover {
            opacity: .5;
            transition: opacity 300ms ease-out;
            -o-transition: opacity 300ms ease-out;
            -moz-transition: opacity 300ms ease-out;
            -webkit-transition: opacity 300ms ease-out;
        }
    }

    &:hover {
        i {
            opacity: 1;
            transition: opacity 300ms ease-out;
            -o-transition: opacity 300ms ease-out;
            -moz-transition: opacity 300ms ease-out;
            -webkit-transition: opacity 300ms ease-out;
        }
    }
}

#personal,
#contact,
#custom {
    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
        width: 100%;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) {
        width: 100%;
    }

    @media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) {
        width: 100%;
    }

    @media only screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3) {
        width: 100%;
    }

    @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
        width: 100%;
    }

    @media screen and (min-width: 960px) {
        width: 100%;
    }

    @media screen and (min-width: 1024px) and (max-device-width: 1136px) {
        width: 90%;
    }

    @media screen and (min-width: 1280px) {
        width: 80%;
    }

    /*@media screen and (min-width: 1366px){
      width:70%;
    }*/
}

.error-message {
    align-self:flex-end;
}

#dateOfBirth {
    @media screen and (min-width: 960px) {
        position: relative;
        bottom: -20px;
    }
}

#smartSchedulingFailedSection md-icon{
    margin: 0px 5px 0px 17px;
}

.md-cancel span{
    color: red;
}

#smartSchedulingFailedSection .message{
    color: red;
}

.md-retry span{
    color: black;
}

.smart-scheduling-success {
    padding-left: 2rem;

    .message {
        margin-left: 1rem;
    }
}