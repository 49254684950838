#timesheet-create {

  .header {
    position: relative;
    .header-content {
      height: 100%;
    }
  }

  [scroll] {
    position: fixed;
    top: 0;
  }

  .center {
    overflow: hidden !important;

    .content {
      margin-top: 0;
      padding-top: 20px;
      padding-bottom: 0;
      background-color: inherit;

      .form-wrapper-2 {
        background: #FFFFFF;
        padding: 10px;
      }

      .form-wrapper {
        margin-top: 5px;
        margin-bottom: 24px;
      }
    }
  }

  // Toolbar fixed
  .toolbar {
    background: #FFFFFF !important;
    border-color: rgba(0, 0, 0, 0.12);
    display: block;
    position: relative;

    &-wrapper {
      //height: 56px;
      position: relative;
      overflow: hidden;
      display: block;

      .item {
        display: inline-block;
        margin-right: 24px;
      }
    }
  }
}
#branch, #custom {
  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }

  @media only screen
  and (min-device-width: 320px)
  and (max-device-width: 568px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }

  @media only screen
  and (min-device-width: 375px)
  and (max-device-width: 667px)
  and (-webkit-min-device-pixel-ratio: 2) {
    width: 100%;
  }

  @media only screen
  and (min-device-width: 414px)
  and (max-device-width: 736px)
  and (-webkit-min-device-pixel-ratio: 3) {
    width: 100%;
  }

  @media only screen
  and (min-device-width: 768px)
  and (max-device-width: 1024px)
  and (-webkit-min-device-pixel-ratio: 1) {
    width: 100%;
  }

  @media screen and (min-width: 960px){
    width:100%;
  }

  @media screen and (min-width: 1024px)
  and (max-device-width: 1136px){
    width:90%;
  }

  @media screen and (min-width: 1280px)
  {
    width:80%;
  }

  /*@media screen and (min-width: 1366px){
    width:70%;
  }*/
}
