#award-rates {

    .draggable-container {
        @extend .mb-10;

        .drag-handle {
            cursor: grab;
        }

        .draggable-item {
            @extend .white-bg;
            @extend .p-10;
            @extend .mb-5;
            border: 1px solid #e6e6e6;

            md-icon {
                @extend .mr-5;
            }
        }
    }
}
