.failed-to-save-setting {
    padding-top: 6px;
    margin-left: 5px;
    color: red;
}

.note-warning {
    color: #ff8100;
    font-size: 12px;
}
#label-hide-notes-in-ticket-pdf {
    position: relative;
}

.flex-3{
    max-width: 3.5%;
    flex: 1 1 100%;
    max-height: 100%;
    box-sizing: border-box;

}

.require-work-order {
    position: relative !important;
}