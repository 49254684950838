#draggable-resource {
    margin-left: 15px;

    md-content {
        md-list {
            md-list-item {
                position: relative;

                flex: 1 1 auto;
                min-height: 25px;
            }

            .md-no-proxy,
            md-list-item .md-no-style {
                padding: 0px 16px;
            }
        }
    }

    .list {
        height: 560px;
        overflow: hidden;
    }

    .list-item {
        margin: 2px;
    }

    .list-image {
        margin-top: 15px;
    }

    .no-style {
        list-style-type: none;
        padding-right: 5px;
        margin-left: -40px;
    }

    .divider {
        border-top-width: 3px;
        margin-top: 20px;
    }

    .assetsSearch {
        margin-bottom: -30px;
    }

    ._md-subheader-content {
        text-align: center;
        border: 1px solid #cccccc;
        padding: 10px 1px 10px 1px;
        background: #a4bacf
    }

    .resource-toolbar {
        background-color: rgb(0, 111, 222);
    }

    .list-scrollNoKits {
        height: 210px;
        display: block;
        position: relative;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .list-scroll {
        height: 130px;
        display: block;
        position: relative;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .list-scroll_equipmentOnlyNoKits {
        height: 450px;
        display: block;
        position: relative;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .list-scroll_equipment {
        height: 300px;
        display: block;
        position: relative;
        font-size: 14px;
        margin-bottom: 10px;
    }

    .md-toolbar-tools {
        font-size: medium;
        height: 40px;
        background-color: #E1E1E1;
        height: 40px;
        color: #4E4E4E;
        box-shadow: 0 1px 0 0px #999999
    }

    .md-subheader ._md-subheader-inner {
        padding: 10px 1px 10px 1px;
    }

    .md-subheader .md-subheader-inner {
        display: block;
        padding: 7px;
        margin-left: 10px;
    }

    .md-subheader.md-default-theme,
    .md-subheader {
        color: rgba(0, 0, 0, 0.54);
        background-color: #eee;
        box-shadow: 0 1px 0 0px #e5e5ee;
    }

    .menu-title {
        text-align: center;
        font-size: 16px;
    }

    .assets-item-inner {
        height: auto;
        min-height: 25px !important;
    }

    .assets-item-inner>p {
        padding: 5px 15px 5px 15px;
        margin: 1px;
    }

    @media only screen and (max-device-width: 600px) {
        margin: 15px 0;
    }
}
