#invoice-email-sender {
    .content {
        overflow: hidden;
    }

    .main-checkbox {
        margin-left: 5px;
        margin-top: 16px;
    }
}

.margin-override{
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    align-items: unset !important;
    align-content: unset !important;
    span,strong,md-switch,md-menu,button{
        align-self: center;
    }
}
