#job-messaging {

   .title-icon {
        color: #FFFFFF !important;
    }

    form {
        font-size: 1.2rem;
    }

    // Sidenav
    .sidenav {
        background-color: rgb(255,255,255);
        box-shadow: none;
        margin: 0;
        padding: 0;
        
        .header {
            color: #FFFFFF;
            
            .logo {
                
                .logo-icon {
                    margin: 0 16px 0 0;
                }

                .logo-text {
                    font-size: 20px;
                }
            }

            .account {
                width: 100%;
           
                .account-selection {
                    margin: 0;
                    padding: 0;

                    .md-select-label {
                        width: 100%;
                    }

                    .md-select-value {
                        padding-left: 0;
                    }
                }
            }
        }

     
        .content {
            padding: 24px 0;
            
            .compose-button {
                padding: 0 24px 24px 24px;

                .md-button {
                    width: 100%;
                    margin: 0;
                }
            }
        }

        &._md-locked-open {

            .header {
                color: #FFFFFF;

                .logo {
                    padding-top: 27px;

                    .logo-icon {

                        md-icon {
                            color: #FFFFFF;
                        }
                    }
                }
            }
        }

        &:not(._md-locked-open) {

            .header {
                height: 150px;
                min-height: 150px;
                max-height: 150px;
                padding-top: 24px;
            }
        }
    }

    .navigation-simple {
        position: relative;
        cursor: pointer;
        text-align: left;
        margin: 0;
        //padding: 0 24px;
        text-transform: none;
        line-height: 16px;
        max-height: 48px;
        height: 48px;

        .sub-header {
            font-size: 12px;
            //border-top: 1px solid rgba(0, 0, 0, 0.12);
            font-weight: 500;
            margin-top: 15px;
            margin-bottom: 25px;
        }

        .legend-item {
            margin-top: 15px ;
            margin-bottom: 15px;
        }

        .status {
            font-size: 12px;
            white-space: nowrap;
            //padding: 2px 5px;
            border-radius: 2px;
            text-align: center;
            height: 16px;
            width: 16px;
            display: inline-block;
        } 
    }
    
}
