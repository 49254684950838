// Spinner
$offset: 187;
$duration: 1.4s;
$color-primary: rgb(0, 115, 232);
$sand: true;

@mixin backgroundChange() {}

ng-form {
    font-size: 1.6rem !important;
}

.md-scroll-mask {
    position: initial !important;
}

.still-fetching {
    opacity: 0.4;
    cursor: wait;
}

.fetched {
    opacity: 1;
    cursor: pointer;
}

.md-accent-bg {
    @include backgroundChange();
}

.md-accent-bg2 {
    color: rgba(255, 255, 255, 1) !important;
    background: rgb(160, 5, 255);
    background: linear-gradient(180deg, rgba(160, 5, 255, 1) 0%, rgba(188, 5, 255, 1) 100%);
}

md-button {
    line-height: 37px;
}

.md-button {
    &.md-raised.md-success {
        color: white !important;
        background-color: #2CE080 !important;
    }

    &.md-success {
        color: #2CE080 !important;
    }
}

button.md-small {
    line-height: 32px;
    min-height: 32px;
    font-size: 12px;
}

.spinner {
    animation: rotator $duration linear infinite;

    &-container {
        text-align: center;
        height: 36px; // height of button
    }
}

.spinner-360 {
    animation: rotator360 3.5s linear infinite;

    &-container {
        text-align: center;
        height: 36px; // height of button
    }
}

.wrap-text {
    word-wrap: break-word;
}

@keyframes rotator {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(270deg);
    }
}

@keyframes rotator360 {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.path {
    stroke-dasharray: $offset;
    stroke-dashoffset: 0;
    transform-origin: center;
    animation: dash $duration ease-in-out infinite, colors ($duration*4) ease-in-out infinite;
}

@keyframes colors {
    0% {
        stroke: #4285F4;
    }

    25% {
        stroke: #DE3E35;
    }

    50% {
        stroke: #F7C223;
    }

    75% {
        stroke: #1B9A59;
    }

    100% {
        stroke: #4285F4;
    }
}

@keyframes dash {
    0% {
        stroke-dashoffset: $offset;
    }

    50% {
        stroke-dashoffset: $offset/4;
        transform: rotate(135deg);
    }

    100% {
        stroke-dashoffset: $offset;
        transform: rotate(450deg);
    }
}

.md-button {
    &.fadeOut {
        //opacity: 0;
        display: none;
        transition: opacity 300ms ease-in-out;
        -moz-transition: opacity 300ms ease-in-out;
        -webkit-transition: opacity 300ms ease-in-out;
    }

    &.fadeIn {
        //opacity: 1;
        display: block;
        transition: opacity 300ms ease-in-out;
        -moz-transition: opacity 300ms ease-in-out;
        -webkit-transition: opacity 300ms ease-in-out;
    }
}

md-input-container[md-input-icon] {
    .md-input-icon {
        font-size: 16px;
        width: 16px;
        height: 16px;
        min-width: 16px;
        min-height: 16px;
        line-height: 16px;
    }

    input {
        padding-left: 24px;
    }
}

.display-block {
    display: block !important;
}

.display-none {
    display: none !important;
}

md-autocomplete>md-autocomplete-wrap>md-progress-linear {
    display: none;
}

/**
 * TODO: Temporary fix for date picker removing border
 */

md-datepicker {
    background: none !important;
}

.alert-error {
    color: #DE3E35;
}

.input-error-message {
    color: #DE3E35;
    font-size: 0.8em;
}

.app-grid-norecord {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    text-align: center;

    span {
        background-color: rgb(245, 245, 245);
        padding: 2px;
    }
}

.truncate {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-base {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.truncate-500 {
    @extend .truncate-base;
    width: 500px;
}

.multiline-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

#head-button {
    background-color: #6fdbff;
    line-height: 0;
    margin-right: -2px;
    line-height: inherit;
}

#splash-screen-logo {
    width: 285px;
    height: auto;
    display: block;
    margin: 0 auto 25px auto;
}

#splash-screen {
    background: rgb(80, 51, 182);
    background: -moz-linear-gradient(180deg, rgba(80, 51, 182, 1) 0%, rgba(188, 5, 255, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(80, 51, 182, 1) 0%, rgba(188, 5, 255, 1) 100%);
    background: linear-gradient(180deg, rgba(80, 51, 182, 1) 0%, rgba(188, 5, 255, 1) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#355eb7', endColorstr='#132138', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
}

.button1 {
    margin: 0px;
}

.first-button-margin {
    margin: 6px 3px 6px 6px;
}

.button-margin {
    margin: 6px 3px 6px 3px;
}


/**
  Tooltip for ReactJs
  */

.custom-tooltip {
    position: relative;
    display: inline-block;
}

.custom-tooltip .custom-tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 999999;
    top: 100%;
    left: 50%;
    margin-left: -60px;
    font-size: 12px !important;
    font-weight: normal;
}

.custom-tooltip .custom-tooltiptext-column-right {
    visibility: hidden;
    width: 80px;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 999999;
    margin-left: 10px;
    font-size: 12px !important;
    font-weight: normal;
    top: 35%;
    right: 35%;
}

.custom-tooltip .custom-tooltiptext-right-hand-side {
    visibility: hidden;
    width: 80px;
    background-color: #616161;
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 999999;
    left: 95%;
    margin-left: 10px;
    font-size: 12px !important;
    font-weight: normal;
}

.custom-tooltip:hover .custom-tooltiptext-column-right {
    visibility: visible;
}

.custom-tooltip:hover .custom-tooltiptext-right-hand-side {
    visibility: visible;
}

.custom-tooltip:hover .custom-tooltiptext {
    visibility: visible;
}

.date-picker {
    padding-right: 18px;
    height: 58px !important;
    margin-top: 8px !important;

    label {
        left: 33px !important;
        top: -35px;
        right: 0 !important;
        width: calc(100% - 33px) !important;
    }

    md-datepicker {
        display: inline-block;
        width: 100%;
        margin-left: 26px;
        height: 0;

        .md-datepicker-button {
            float: left;
            margin-top: -12.5px;
            margin-left: -33px;
        }

        .md-datepicker-input-container {
            width: 100%;
            top: -14px;
            right: 17px;

            .md-datepicker-input {
                max-width: 100% !important;
                min-width: 100% !important;
            }

            .md-datepicker-expand-triangle {
                border-left: 4px solid transparent;
                border-right: 4px solid transparent;
                border-top: 4px solid;
            }
        }
    }

    .md-input-messages-animation {
        .md-input-message-animation {
            opacity: 1;
            margin-top: 6px !important;
            margin-left: 34px !important;
        }
    }
}

.list-none {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}


/**
* React JS Linear Progress
*/

.react-linear-activity {
    overflow: hidden;
    width: 50%;
    height: 4px;
    background-color: #B3E5FC;
    margin: 5px auto;
}

.react-determinate {
    position: relative;
    max-width: 100%;
    height: 100%;
    -webkit-transition: width 500ms ease-out 1s;
    -moz-transition: width 500ms ease-out 1s;
    -o-transition: width 500ms ease-out 1s;
    transition: width 500ms ease-out 1s;
    background-color: rgb(69,90,100);
}

.react-indeterminate {
    position: relative;
    width: 100%;
    height: 100%;
}

.react-indeterminate:before {
    content: '';
    position: absolute;
    height: 100%;
    background-color: rgb(69,90,100);
    animation: indeterminate_first 1.5s infinite ease-out;
}

.react-indeterminate:after {
    content: '';
    position: absolute;
    height: 100%;
    background-color: rgb(69,90,100);
    animation: indeterminate_second 1.5s infinite ease-in;
}

@keyframes indeterminate_first {
    0% {
        left: -100%;
        width: 100%;
    }

    100% {
        left: 100%;
        width: 10%;
    }
}

@keyframes indeterminate_second {
    0% {
        left: -150%;
        width: 100%;
    }

    100% {
        left: 100%;
        width: 10%;
    }
}


/**
* React JS checkbox
*/

.react-checkboxtext {
    /* Checkbox text */
    font-size: 110%;
    display: inline;
}

.react-checkbox {
    position: relative;
    top: 3px;
    //margin: 0 1rem 0 0;
    cursor: pointer;
    -ms-transform: scale(1.5);
    /* IE */
    -moz-transform: scale(1.5);
    /* FF */
    -webkit-transform: scale(1.5);
    /* Safari and Chrome */
    -o-transform: scale(1.5);
    /* Opera */
    padding: 10px;
    margin-left: 0.100rem;
    border: 2px solid #5990c8;
}

.react-checkbox-disabled {
    position: relative;
    top: 3px;
    //margin: 0 1rem 0 0;
    cursor: pointer;
    -ms-transform: scale(1.5);
    /* IE */
    -moz-transform: scale(1.5);
    /* FF */
    -webkit-transform: scale(1.5);
    /* Safari and Chrome */
    -o-transform: scale(1.5);
    /* Opera */
    padding: 10px;
    margin-left: 0.100rem;
    border: 2px solid #BDBDBD !important;
    cursor: pointer;
}

.react-checkbox:before {
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    content: "";
    position: absolute;
    left: 0;
    z-index: 1;
    width: 1rem;
    height: 1rem;
    //border: 2px solid #dadada;
    border-radius: 2px;
    border: 1px solid rgba(109, 107, 107, 0.87);
}

.react-checkbox:checked:before {
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
    height: .5rem;
    border-radius: 0;
    border-width: 2px;
    border-color: #5990c8;
    border-top-style: none;
    border-right-style: none;
}

.react-checkbox:after {
    content: "";
    position: absolute;
    //top: -0.125rem;
    left: 0;
    width: 1.3rem;
    height: 1.3rem;
    background: #fff;
    cursor: pointer;
}


/**
* React JS checkbox  --->
*/

.dp-places-map-wrapper .dp-places-map-input {
    border: 1px solid transparent;
    border-radius: 2px 0 0 2px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    height: 32px;
    outline: none;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-size: 15px;
    font-weight: 300;
    margin: 30px 0 0 12px;
    padding: 0 11px 0 13px;
    text-overflow: ellipsis;
    width: calc(58%)
}

.dp-places-map-wrapper .dp-places-map-input:focus {
    border-color: #4d90fe
}

.dp-places-map-wrapper .dp-places-map-canvas {
    width: 100%;
    height: 400px
}

.dp-places-map-wrapper .dp-places-map-canvas.responsive {
    width: 100%
}

@media (min-width:468px) {
    .dp-places-map-wrapper .dp-places-map-input {
        width: 300px
    }
}

@media (min-width:830px) {
    .dp-places-map-wrapper .dp-places-map-input {
        width: 400px
    }
}

@keyframes borderscale {
    50% {
        box-shadow: 0 0 0 2px #4f8196;
    }
}

.bold-text {
    font-weight: 500;
}

.save_loader_style {
    margin: auto;
    z-index: 100;
    position: absolute;
    top: 50%;
    @extend .bold-text;
}

.dim_on {
    opacity: 0.2;
    z-index: 1;
}

.dim_off {
    opacity: 1;
    z-index: -4;
}


/**
* Clickable/not clickable
*/

.clickable-item {
    cursor: pointer;
}

.not-clickable-item {
    cursor: not-allowed;
}


/**
* =============================
* ReactJS Popup Menu
* using jquery contextMenu lib
* =============================
*/

.context-menu-icon::before {
    color: #000;
}

.context-menu-icon.context-menu-icon--fa::before {
    color: #000;
}

.context-menu-icon.context-menu-icon--fa::after {
    color: #000;
}

.context-menu-item.context-menu-hover {
    color: #616161;
    background-color: rgba(238, 238, 238, 0.7);
}

.context-menu-list {
    padding: 1.5em 0;
}

.context-menu-item {
    padding: 1.5em 2em 1.5em 3em;
}


/**
* =======================
*  Back to Top
* =======================
*/


/*----------Back to Top Button -----------*/

.btt-btn {
    cursor: pointer;
    position: fixed;
    bottom: .5em;
    right: .5em;
    padding: .5em;
    text-decoration: none;
    color: white;
    background-color: rgba(0, 0, 0, 0.2);
    font-size: 14px;
    outline-style: none;
    /*display: none;*/
}

.btt-btn:hover {
    background-color: rgba(0, 0, 0, 0.6);
}

.btt-btn:active {
    background-color: rgba(0, 0, 0, 0.8);
}

/**
* =============================
*
*/

/**
 *  Do not remove this comments bellow. It's the markers used by wiredep to inject
 *  sass dependencies when defined in the bower.json of your dependencies
 */

// bower:scss
@import "../../bower_components/mdi/scss/materialdesignicons.scss";
@import "../../bower_components/select2/src/scss/core.scss";
// endbower

/**
 *  Do not remove this comments bellow. It's the markers used by gulp-inject to inject
 *  all your sass files automatically
 */

// injector
@import "core/scss/global.scss";
@import "core/theme-options/theme-options.scss";
@import "core/directives/ms-navigation/ms-navigation.scss";
@import "core/directives/ms-responsive-table/ms-responsive-table.scss";
@import "core/directives/ms-scroll/ms-scroll.scss";
@import "core/directives/ms-splash-screen/ms-splash-screen.scss";
@import "core/directives/ms-widget/ms-widget.scss";
@import "main/assets/manage/category/asset-category.scss";
@import "main/assets/manage/costCodes/cost-codes.scss";
@import "main/assets/manage/item/item.scss";
@import "main/assets/manage/kit/kit.scss";
@import "main/awards/manage/addons/award-addons.scss";
@import "main/awards/manage/allowance-rates/award-allowance-rates.scss";
@import "main/awards/manage/allowances/award-allowances.scss";
@import "main/awards/manage/classification-rates/award-classification-rates.scss";
@import "main/awards/manage/classifications/award-classifications.scss";
@import "main/awards/manage/dialogs/award-conditions-dialog.scss";
@import "main/awards/manage/rates/award-rates.scss";
@import "main/awards/manage/rules/award-rules.scss";
@import "main/awards/manage/view/awards-view.scss";
@import "main/company/create/companyDetails/company-details.scss";
@import "main/contact/calendar/add/add-contact-calendar.scss";
@import "main/contact/create/work/work.scss";
@import "main/costEstimate/directives/grid/ce-grid.scss";
@import "main/costEstimate/preview/sidenav/main-sidenav.scss";
@import "main/customFieldsManager/views/sidenav/main-sidenav.scss";
@import "main/integrations/directives/settings/connector.scss";
@import "main/integrations/directives/settings/settings.scss";
@import "main/integrations/directives/tabs/tabs.scss";
@import "main/invoice/directives/invoiceGrid/invoice-grid.scss";
@import "main/invoice/emailSender/sidenav/main-sidenav.scss";
@import "main/invoice/exportViewer/directives/invoice-error-column.scss";
@import "main/invoice/forms/manual-invoice/manual-invoice.scss";
@import "main/invoice/reports/sidenav/main-sidenav.scss";
@import "main/jobs/resourceSidenav/advanceOptions/advanceOptions.scss";
@import "main/jobs/resourceWhiteboard/wboard/resource-wboard.scss";
@import "main/jobs/services/cloneAllocationDialog/clone-allocation.scss";
@import "main/jobs/ticketBilling/main/ticket-billing-main.scss";
@import "main/jobs/ticketBilling/sidenav/main-sidenav.scss";
@import "main/jobs/whiteBoard/jobBoard/job-board.scss";
@import "main/jobs/widget/DraggableComponent/Draggable-Resource.scss";
@import "main/jobs/widget/DroppedAssetComponent/Dropped-Asset.scss";
@import "main/jobs/widget/JobDuration/job-duration.scss";
@import "main/messaging/generalMessaging/sendMessageAsService/resource-messaging.scss";
@import "main/messaging/jobMessaging/sidenav/main-sidenav.scss";
@import "main/pages/auth/forgot-password/forgot-password.scss";
@import "main/pages/auth/login-v2/login-v2.scss";
@import "main/pages/auth/update-password/update-password.scss";
@import "main/pages/errors/403/error-403.scss";
@import "main/pages/errors/404/error-404.scss";
@import "main/pages/errors/500/error-500.scss";
@import "main/pages/errors/unhandled/error-unhandled.scss";
@import "main/paperwork/manage-paperwork/download/download-paperwork.scss";
@import "main/paperworkExceptions/directives/mainView/main-container.scss";
@import "main/rateCardSettings/directives/addPackageGroup/addPackageGroupComponent.directive.scss";
@import "main/ratecards/manage/dialog/ratecard-manage-packages.scss";
@import "main/skillsmatrix/directive/addskill/add-skill.scss";
@import "main/skillsmatrix/directive/addskillgroup/add-skillgroup.scss";
@import "main/training/directive/addtraining/add-training.scss";
@import "main/training/directive/addtraininggroup/add-traininggroup.scss";
@import "main/training/directive/removetraining/remove-training.scss";
@import "main/vehicle/directive/addvehicle/add-vehicle.scss";
@import "main/vehicle/directive/removevehicle/remove-vehicle.scss";
@import "shared/widgets/a8PaginationGrid/sort/a8SortColumn.scss";
@import "main/assets/manage/item/individualItem/import-dialog.scss";
@import "main/assets/manage/item/individualItem/individual-item.scss";
@import "main/assets/manage/item/itemView/item-view.scss";
@import "main/assets/manage/kit/kitview/kit-view.scss";
@import "main/contact/calendar/dialogs/event-detail/event-detail-dialog.scss";
@import "main/contact/calendar/dialogs/event-form/event-form-dialog.scss";
@import "main/contact/create/directives/componentHeader/contactComponentHeader.scss";
@import "main/contact/create/directives/fileViewer/fileViewer.scss";
@import "main/contact/create/directives/highlightBox/highlight-box.scss";
@import "main/contact/create/work/company/workInfo-company.scss";
@import "main/costEstimate/preview/dialogs/edit-ce/ce-edit-line-item-dialog.scss";
@import "main/costEstimate/preview/directives/ceContent/ce-content-view.scss";
@import "main/invoice/emailSender/dialogs/company-recipient/company-recipient.scss";
@import "main/invoice/emailSender/dialogs/download/download-invoice.scss";
@import "main/invoice/emailSender/dialogs/issue-dialog/issue-dialog..scss";
@import "main/invoice/emailSender/dialogs/recipient/manage-recipient.scss";
@import "main/invoice/emailSender/dialogs/recipient-logs/recipient-logs.scss";
@import "main/invoice/emailSender/directives/emailGridContent/email-grid-content.scss";
@import "main/invoice/emailSender/directives/emailSenderContent/email-sender-content-view.scss";
@import "main/invoice/reports/dialogs/edit-invoice/edit-invoice-item-dialog.scss";
@import "main/invoice/reports/directives/invoiceContent/invoice-content-view.scss";
@import "main/jobs/allocateResource/components/draggableComponent/Draggable-Resource.scss";
@import "main/jobs/allocateResource/components/draggableComponent/sidenav.scss";
@import "main/jobs/confirmBooking/components/DroppableConfirmTicketComponent/Droppable-Confirm-Ticket.scss";
@import "main/jobs/confirmBooking/components/confirmTicketComponent/confirm-ticket.scss";
@import "main/jobs/formsAndAttachments/components/JobTagsComponent/job-tags.scss";
@import "main/jobs/manage/components/JobDetailsComponent/job-details.scss";
@import "main/jobs/manage/components/OrderedByComponent/order-by.scss";
@import "main/jobs/resourceSidenav/components/options/resource-options.directive.scss";
@import "main/jobs/resourceSidenav/components/resourceList/resource-list.scss";
@import "main/jobs/resourceWhiteboard/wboard/daily/jday.scss";
@import "main/jobs/resourceWhiteboard/wboard/daily/jobBoardDayViewRwComponent.scss";
@import "main/jobs/resourceWhiteboard/wboard/resources/jobBoardResources.scss";
@import "main/jobs/resourceWhiteboard/wboard/ticketInfoNumber/ticketNumber.directive.scss";
@import "main/jobs/resourceWhiteboard/wboard/weekly/jobBoardRwComponent.scss";
@import "main/jobs/resourceWhiteboard/wboard/weekly/jweek.scss";
@import "main/jobs/timeSheet/service/submitTimesheetDialog/submitTimesheetDialog.scss";
@import "main/jobs/whiteBoard/jobBoard/daily/jobBoardDayViewComponent.scss";
@import "main/jobs/whiteBoard/jobBoard/dateNavigation/dateNavigation.scss";
@import "main/jobs/whiteBoard/jobBoard/weekly/jobBoardComponent.scss";
@import "main/jobs/widget/JobLocation/add/address.scss";
@import "main/lockoutSettings/view/components/rulesList/rule-list.scss";
@import "main/messaging/generalMessaging/components/contactsArea/contactsArea.scss";
@import "main/messaging/generalMessaging/components/dialog/message-dialog.scss";
@import "main/messaging/generalMessaging/sendMessageAsService/contacts/contacts.scss";
@import "main/messaging/generalMessaging/sendMessageAsService/message/message.scss";
@import "main/messaging/jobMessaging/dialogs/message-status-logs/message-dialog..scss";
@import "main/messaging/jobMessaging/directives/jobMessagingContent/job-messaging-content-view.scss";
@import "main/messaging/jobMessaging/directives/jobMessagingGridContent/job-messaging-grid-content.scss";
@import "main/pages/profile/tabs/my-details/my-details.scss";
@import "main/pages/profile/tabs/my-settings/my-settings.scss";
@import "main/reporting/view/report/skillStatus/skill-status-report.scss";
@import "main/contact/create/work/allowances/dialog/contact-allowances.scss";
@import "main/jobs/allocateResource/components/droppableComponent/ResourceDialog/resource-dialog.scss";
@import "main/jobs/allocateResource/components/droppableComponent/jobAllocation/resource-messaging.scss";
@import "main/jobs/allocateResource/components/resourceLike/dialog/resource-like.scss";
@import "main/jobs/allocateResource/components/resourceLike/errorDialog/resource-like-error.scss";
@import "main/jobs/manage/components/ResourceComponent/DroppableComponent/Droppable-Resource.scss";
@import "main/jobs/relatedTicketBillingPreferences/services/dialogs/singleDay/single-day.scss";
@import "main/jobs/whiteBoard/jobBoard/components/filter/filter-whiteboard.scss";
@import "main/jobs/whiteBoard/jobBoard/dialogs/cardTicketDialog/card-ticket-dialog.scss";
@import "main/jobs/whiteBoard/jobBoard/dialogs/ticketDetailDialog/ticket-detail-dialog.scss";
@import "main/messaging/generalMessaging/components/messageArea/contacts/contacts.scss";
@import "main/messaging/generalMessaging/components/messageArea/message/message.scss";
@import "main/messaging/generalMessaging/sendMessageAsService/message/textArea/textArea.scss";
@import "main/paperworkExceptions/directives/mainView/gridContainer/dynamicGrid/dynamicGrid.scss";
@import "main/company/create/companyDetails/Skills/templates/add/company-skill.scss";
@import "main/company/create/companyDetails/Skills/templates/edit/company-skill.scss";
@import "main/invoice/reports/dialogs/edit-invoice/directives/editable-table/editable-table.scss";
@import "main/messaging/generalMessaging/components/messageArea/message/textArea/textArea.scss";
@import "main/costEstimate/preview/dialogs/edit-ce/directives/editable-line-item/invoiceRateCardItemSelect/ceInvoiceRateCardItem.scss";
@import "main/invoice/reports/dialogs/edit-invoice/directives/editable-line-item/invoiceRateCardItemSelect/invoiceRateCardItem.scss";
@import "main/jobs/allocateResource/components/droppableComponent/components/droppable-components/BT/Droppable-Resource.scss";
@import "main/jobs/allocateResource/components/droppableComponent/components/droppable-components/SM/Droppable-Resource.scss";
@import "main/jobs/allocateResource/components/droppableComponent/components/droppable-components/XL/Droppable-Resource.scss";
@import "main.scss";
@import "navigation/navigation.scss";
@import "quick-panel/quick-panel.scss";
@import "toolbar/toolbar.scss";
@import "main/assets/assets.scss";
@import "main/branch/branch-create.scss";
@import "main/business/business-view.scss";
@import "main/commentRestriction/manage-comment-restrictions.scss";
@import "main/customValidations/custom-validations.scss";
@import "main/dashboard/dashboard.scss";
@import "main/generalSettings/generalSettings.scss";
@import "main/integrations/integrations.scss";
@import "main/messagingFormat/messaging-format.controller.scss";
@import "main/paperwork/paperwork.scss";
@import "main/payrollGroups/payroll-group.scss";
@import "main/rateCardSettings/rateCardSettings.scss";
@import "main/ratecards/ratecards.scss";
@import "main/tags/tags.scss";
@import "main/webhooks/webhooks.scss";
@import "main/assets/directives/hire-rate.scss";
@import "main/awards/interpreter/awards-interpreter.scss";
@import "main/branch/view/branch-view.scss";
@import "main/business/edit/business-edit.scss";
@import "main/company/create/company-create.scss";
@import "main/company/view/company-view.scss";
@import "main/contact/calendar/contact-calendar.scss";
@import "main/contact/create/contact-create.scss";
@import "main/contact/details/contact-dialog.scss";
@import "main/contact/view/contact-view.scss";
@import "main/costEstimate/preview/cost-estimate-preview.scss";
@import "main/costEstimate/view/ce-view.scss";
@import "main/customFieldsManager/views/custom-fields-manager.scss";
@import "main/customForms/components/formTemplates-formSettings.scss";
@import "main/customForms/directives/form-builder.scss";
@import "main/customForms/edit/formTemplates-edit.scss";
@import "main/customForms/view/formTemplates-view.scss";
@import "main/equipment/calendar/equipment-calendar.scss";
@import "main/holidays/view/holidays-view.scss";
@import "main/invoice/emailSender/email-sender.scss";
@import "main/invoice/exportViewer/invoice-export-viewer.scss";
@import "main/invoice/reports/invoice-report.scss";
@import "main/invoice/view/invoice-view.scss";
@import "main/invoiceExport/manage/invoice-export-manage.scss";
@import "main/jobs/allocateResource/allocate-resource.scss";
@import "main/jobs/formsAndAttachments/forms-attachments.scss";
@import "main/jobs/manage/job-manage.scss";
@import "main/jobs/mapView/jobs-map-view.scss";
@import "main/jobs/relatedTicketBillingPreferences/related-ticket-billing.scss";
@import "main/jobs/resourceSidenav/resourceSidenav.scss";
@import "main/jobs/timeSheet/job-timesheet.scss";
@import "main/jobs/view/jobs-view.scss";
@import "main/lockoutSettings/view/lockout-settings.scss";
@import "main/messaging/generalMessaging/messaging.scss";
@import "main/messaging/jobMessaging/job-messaging.scss";
@import "main/pages/profile/profile.scss";
@import "main/paperwork/manage-paperwork/manage-paperwork.scss";
@import "main/paperworkExceptions/sidenav/main-sidenav.scss";
@import "main/payroll/allowances/allowances.scss";
@import "main/payroll/timesheet/payroll-timesheet.scss";
@import "main/payroll/update-payroll-contacts/update-payroll-contacts.scss";
@import "main/payrollSettings/manage/payroll-settings-manage.scss";
@import "main/rateCardSettings/allowances/allowances.scss";
@import "main/rateCardSettings/packages/packages.scss";
@import "main/rateCardSettings/timesheets/timesheets.scss";
@import "main/ratecards/manage/ratecard-manage.scss";
@import "main/ratecards/pricing/ratecard-pricing.scss";
@import "main/reporting/view/view_reports.scss";
@import "main/skillsmatrix/manage/skillsmatrix.scss";
@import "main/training/manage/training.scss";
@import "main/useraccess/view/useraccess-view.scss";
@import "main/vehicle/manage/vecicle.scss";
@import "quick-panel/tabs/todo/todo-tab.scss";
@import "shared/coreLib/ms-scroll2/ms-scroll.scss";
@import "shared/widgets/a8BackToTop/a8BackToTop.scss";
@import "shared/widgets/a8Component_company_address/address.scss";
@import "shared/widgets/a8Header/a8-header.scss";
@import "shared/widgets/a8HeaderWithSearch/a8-header-with-search.scss";
@import "shared/widgets/a8PaginationGrid/a8Grid.scss";
@import "shared/widgets/comment/comment.scss";
@import "shared/widgets/composedEmailTemplateDialog/composedEmailTemplateDialog.scss";
@import "shared/widgets/conditionComponent/conditionContentComponent.directive.scss";
@import "shared/widgets/contactPreference/contact-preference.scss";
@import "shared/widgets/contactPreference2/contact-preference.scss";
@import "shared/widgets/documentManager/documentManager.directive.scss";
@import "shared/widgets/formTemplatesList/formTemplates-list.scss";
@import "shared/widgets/historyLogPage/history-log-page.scss";
@import "shared/widgets/jobForms/jobForms-list.scss";
@import "shared/widgets/tableSearchDirective/table-search.scss";
@import "shared/widgets/toolbarLoader/toolbar-loader.scss";
// endinjector
