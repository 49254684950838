
#kit-view {

  .content {
    margin-top: 5px;
    padding-top: 0;

    .form-wrapper2 {
       background: #FFFFFF;
       padding: 16px;

      .form-title {
          font-size: 21px;
          padding: 8px 0;
      }

      .md-button{
        margin-left: 0;
      }
    }

    td {
      padding-top: 3px;
      padding-bottom: 3px;  
    }

    .kit-list {
        list-style: none;
        font-size: 0.9em;
    }
  }
  
}

