#form-template-edit {
    .content {
        margin-top: 5px;
        padding-top: 20px;

        .content-header {
            margin-bottom: 10px;
            border-bottom: 1px solid #c5c5c5;
        }

        .form-wrapper2 {
            background: #FFFFFF;
            padding: 16px;

            .form-title {
                font-size: 21px;
                padding: 8px 0;
            }

            .md-button {
                margin-left: 0;
            }
        }
    }

    .toolbar-custom {
        background-color: #0671ec;
        box-shadow: 2pt 5pt 8pt 0pt rgba(0, 0, 0, 0.5);
    }

    .md-toolbar-tools {
        font-size: 1em;
        background-color: #ffffff;
        padding: 14px;
    }

    .header-text {
        color: #FFFFFF;
        font-size: 20px;
    }

    .text {
        font-size: 14px;
        margin-bottom: 0px;
        margin-top: 5px;
    }

    .text-label {
        font-weight: bold;
    }

    .fb-control-header {
        background-color: #333333;
    }

    .fb-header-text {
        color: #FFFFFF;
        font-size: 18px;
    }

    .fb-control-button {
        background-color: #0671ec;
        color: #FFFFFF;
        font-weight: normal;
        width: 90%;
        margin-left: 10px;
        margin-right: 10px;
        text-align: left;
    }

    .icon-button {
        cursor: pointer;
    }

    #jobFieldGroup-control-group .jobFieldGroup-control,
    #workerFieldGroup-control-group .workerFieldGroup-control,
    #vehicleFieldGroup-control-group .vehicleFieldGroup-control,
    #assetFieldGroup-control-group .assetFieldGroup-control {
        button .control-icon {
            float: none;
            margin-right: 0px;
        }
    }
}