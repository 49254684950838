.companyFeature .text-capitalize {
  text-transform: capitalize; }

.companyFeature .md-fab:hover, .fabSpeedDialdemoBasicUsage .md-fab.md-focused {
  background-color: #ddd !important;
 }

#email{
  padding-top : 10px;

  md-card-content{
    padding-top : 5px;
    padding-bottom : 2px;

    #main-content{
      padding-top : 5px;
      padding-bottom : 10px;
    }
  }
}

#address{
  padding-top : 10px;

  md-card-content{
    padding-top : 5px;
    padding-bottom : 2px;

    #main-content{
        padding-top : 2px;
        padding-bottom : 10px;
    }
  }
}

#numbers{
  padding-top : 10px;

  md-card-content{
    padding-top : 5px;
    padding-bottom : 2px;

    #main-content{
      padding-top : 2px;
      padding-bottom : 10px;
    }
  }
}

#company{
  padding-top : 10px;

  #company-header{
    padding-top : 5px;

    #company-header-content{
      padding-top : 5px;
      padding-bottom : 10px;
    }
  }

  #company-body{
    padding-top : 5px;
    padding-bottom : 2px;

    #company-body-content{
      padding-top : 5px;
      padding-bottom : 10px;
    }
  }
}


.companyFeature p.note {
  font-size: 1.2rem; }

.companyFeature .lock-sizex {
  min-width: 300px;
  min-height: 300px;
  width: 300px;
  height: 300px;
  margin-left: auto;
  margin-right: auto; }

  .scroll-container-company-contact {
      position: relative;
      max-height: 300px;

    md-list {
      width: 100%;
    }
  }
