#profile {
    .content {

        md-content {
            background-color: transparent;
            margin: 16px auto 48px auto;
            padding: 24px;
            width: 1000px;
        }
    }

    // Profile boxes
    .profile-box {
        margin-bottom: 16px;

        header {
            padding: 16px;

            .title {
                font-size: 17px;
            }

            .more {
                cursor: pointer;
            }
        }

        .content {
            padding: 16px;
            background-color: #FFF;
        }

        footer {
            padding: 8px;
            border-top: 1px solid rgba(0, 0, 0, 0.08);
            background-color: rgba(0, 0, 0, 0.06);
        }

        &.info-box {

            .info-line {
                margin-bottom: 24px;

                .title {
                    font-size: 15px;
                    font-weight: 500;
                    padding-bottom: 4px;
                }

                .info {

                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}


// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

    #profile {

        .header {

            .profile-image {
                margin: 0 0 16px 0;
            }

            .name {
                margin-bottom: 32px;
            }
        }
    }

}
