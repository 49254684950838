.invoice-export {
    .invoice-export-format {
        #ExportFormat{
            font: 12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
        }
    }

    .export-fields {
        height: 25em;
        overflow: auto;
    }

    .ace_editor {
        height: 300px;
        border: 1px solid;
        padding: 10px;
        box-shadow: 1px 1px 1px 1px #888888;
    }
}
