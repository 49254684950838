#contact-create-company {
    .column-left {
        margin-right: 24px;
        md-autocomplete {
            width: 100%;
        }
    }
    .column-right {
        //
    }
}