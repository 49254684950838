.consumerFields{
    white-space: pre;
}
.form-template-dialog {
    font-size: 18px;

    .help-text {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .label-text {
        font-size: 18px;
        font-weight: bold;
    }

    .form-name-text {
        font-size: 26px;
        height: 50px;
    }

    .option-text {
        font-size: 16px;
    }

    .md-inline-form md-checkbox {
        margin: 10px 0 7px;
    }

    .required-star{
        color:#fc2f56;
    }
}