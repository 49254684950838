.forms-attachments-page {

    .toolbar {
        background: #FFFFFF !important;
        border-color: rgba(0, 0, 0, 0.12);
        display: block;
        position: relative;

        &-wrapper {
            position: relative;
            overflow: hidden;
            display: block;

            .item {
                display: inline-block;
                margin-right: 24px;
            }
        }

        .toolbar-wrapper button {
            margin-right: 5px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .first-button-margin {
        margin: 6px 3px 6px 6px;
    }

    .button-margin {
        margin: 6px 3px 6px 3px;
    }

    .forms-manager {

        .list-forms__header,
        .add-form__header {
            font-size: 2rem;
            margin-block-start: 0.83em;
            margin-block-end: 0.83em;
        }
    }

    .document-manager {
        .document-manager-header {
            font-size: 2rem;
            margin-block-end: 0.83em;
        }
    }

    .tag-form {
        .tag-form-header {
            font-size: 2rem;
        }
    }
}
