#invoice-export {
    .page-layout.simple.right-sidenav>.center {
        overflow: hidden !important;
    }

    .column-select-item {
        min-width: 90px;
        width: 90px;
    }

    .simple.a8grid {
        md-checkbox {
            margin-top: 0px;
            margin-bottom: 0px;
        }
    }

    .dropdown-instruction {
        position: relative;
        font-size: 12px;
        margin-top: 10px;
        margin-left: 24px;
        margin-bottom: -14px;
        color: #00000061;
    }

    .instruction-warning {
        color: #ff8100;
    }

    .content {
        margin-top: 5px;
        padding-top: 20px;
        background-color: inherit;

        .form-wrapper2 {
            background: #FFFFFF;
            padding: 16px;

            .form-title {
                font-size: 21px;
                padding: 8px 0;
            }

            .md-button {
                margin-left: 0;
            }
        }
    }

    .toolbar-custom {
        background-color: #FFFFFF;
    }

    .toolbar-wrapper {
        margin-left: 15px;
    }

    .export-filter-component {
        padding: 15px;
    }

    .buttons-bar button {
        min-width: 150px;
        min-height: 35px;
    }
}
