#allowances {
    .header-text {
        margin-left: 22px;
        padding-bottom: 16px;
    }

    table.dataTable thead tr {
        background-color: #f2f0f2;
    }

    .dataTables_length {
        margin-top: 26px;
        margin-left: 20px;
    }

    .dataTables_info {
        clear: none;
        margin-top: 17px;
        margin-left: 60px;
    }

    .dataTables_paginate {
        margin-top: 15px;
        margin-right: 30px;
        margin-bottom: 20px;
    }

    div.white-bg {
        margin-bottom: 20px;
    }
}

.field-note {
    position: relative;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.38);
    margin-top: 12px;
    margin-bottom: 10px;
}