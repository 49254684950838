    .forms-manager {
        margin-top: 10px;
        margin-bottom: 20px;

        .error-note {
            font-size: 11px;
            color: #F44336;
        }

        .hiddencol{
            display: none;
        }

        .list-forms {
            &__header {
                margin-bottom: 10px;
            }
        }

        .add-form {
            margin-left: 10px;

            &__header {
                margin-bottom: 10px;
            }
        }

        .search-form {
            margin-bottom: 10px;
            background-color: #ececec;
        }

        .search-form input {
            background-color: inherit;
        }

        .btn-add-form {
            font-size: 12px;
            line-height: 20px;
            height: 20px;
            min-width: 60px;
        }

        table.dataTable {
            width: 100% !important;
        }

        table.dataTable th {
            padding: 10px;
        }

        table.dataTable th.name {
            padding-left: 10px;
        }

        table.dataTable th.unlink {
            width: 70px !important;
            padding-right: 0;
        }

        table.dataTable td.unlink button {
            margin-left: 0;
            padding-left: 0;
        }

        table.dataTable tbody td {
            padding: 10px;
            word-break: break-all;
        }

        .dataTables_wrapper {
            height: 100%;
            padding: 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }

        .dataTables_info {
            padding-left: 5px;
            padding-bottom: 5px;
            flex-grow: 1;
            top: 40px;
            width: 50%;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }

        .dataTables_paginate {
            width: 100%;
            text-align: right;
            padding-bottom: 5px;
        }

        .dataTables_info,
        .dataTables_paginate {
            margin: 0px;
        }
    }
