$quickPanelWidth : 330px;

#quick-panel {
    width: $quickPanelWidth;
    min-width: $quickPanelWidth;
    max-width: $quickPanelWidth;
    z-index: 99;

    md-content {
        height: 100%;

        md-tabs {
            height: 100%;

            md-tabs-wrapper {
                background-color: rgba(0, 0, 0, 0.03);
                border-bottom: 1px solid rgba(0, 0, 0, 0.06);

                .md-tab {
                    max-width: none !important;
                    text-transform: none;
                }
            }

            md-tabs-content-wrapper {

                md-tab-content {
                    height: 100%;
                    overflow: hidden;

                    >div {
                        overflow: hidden;
                        height: 100%;
                    }
                }
            }
        }
    }
}
