#resourceSidenav {
    .divisions {
        position :
            relative; top : -5px
    }

    .search {
        position :
        relative; top : -15px
    }
}
