.custom-fields-manager {
    .card-5 {
        box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
    }

    .side-selected {
        max-width : 3px;
        position : relative;
        background-color: #3A7D2E;
    }

    .side-saving {
        max-width : 5px;
        position : relative;
        background-color: #4262dd;
    }

    .side-no-items {
        max-width : 2px;
        position : relative;
        background-color: #CA2D2A;
    }

    .side-not-selected {
        max-width : 2px;
        position : relative;
        background-color: #0b93d5;
    }

    @keyframes added {
        from {
            opacity: 0;
            transform: rotateX(-90deg);
            transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
        }
        to {
            opacity: 1;
            transform: none;
            transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
            perspective: 100px;
        }
    }

    @keyframes deleted {
        from {
            opacity: 1;
            transform: none;
            transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
            perspective: 100px;
        }
        to {
            opacity: 0;
            transform: rotateX(-90deg);
            transition: all 0.5s cubic-bezier(.36,-0.64,.34,1.76);
        }
    }

    .task-item.ng-enter {
        animation: 0.5s linear added;
    }

    .task-item.ng-leave {
        animation: 0.5s linear deleted;
    }

    .selected {
        opacity : 1;
    }

    .not-selected {
        opacity : 0.5;
    }

}
