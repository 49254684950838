#business-view {
    .div-search {
        padding-bottom: 30px;
    }

    .btn-add {
        float: right;
        margin-top: -30px;
        margin-bottom: 25px;
    }

    .md-icon-button {
        margin-left: 0;
        padding-left: 0;
    }

    tr.inactive {
        color: rgb(170, 170, 170) !important;
    }

    .dataTables_filter {
        display: none;
    }

    .header-bg-color {
        background-color: #f2f2f2;
    }

    .search-container {
        margin-top: 10px;
        margin-bottom: 10px;
        box-shadow: none;
    }
    
    .search {
        background: #FFFFFF;
        margin-top: 5px;
        padding-left: 10px;
    
        .icon {
            margin: 0;
        }
    
        input {
            padding-left: 16px;
            height: 56px;
            color: rgba(0, 0, 0, 0.54);
        }
    }
}

#dialogContent_business-create-dialog {
    padding: 0px 24px 24px 24px;
}

#dialogContent_history-log {
    table {
        td {
            table {
                border: 2px solid #cccccc;
                border-collapse: collapse;
                margin-top: 10px;
                th {
                    border-bottom: 2px solid #cccccc;
                }

                td {
                    border-bottom: 1px solid #cccccc;
                    &:first-child {
                        width: 30%;
                    }

                    &.logo {
                        img {
                            width:auto;
                            height: auto;
                            max-width: 250px;
                            max-height: 96px;
                        }
                    }
                }
            }
        }
    }
}