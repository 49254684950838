#dropped-asset {
    .muted-text {
        color: rgba(0,0,0,0.38);
    }

    md-card {
        margin: 0px;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);

        md-card-content {
            display: block;
            padding: 5px;

            .deleted {
                border: 1px solid red !important;
            }

            md-divider {
                position: initial;
            }
        }
    }

    md-dialog-content {
        padding: 0;

        .issue {
            color: #DE3E35;
            margin-bottom: 15px;
        }

        md-radio-group md-radio-button {
            margin-top: 15px;
            width: 100% !important;
        }
    }

    .elementToFadeInAndOut {
        -webkit-animation: fadeinout 2s linear 1 forwards;
        animation: fadeinout 2s linear 1 forwards;
    }

    @-webkit-keyframes fadeinout {
        0% {
            background: #999999;
            color: #FFFFFF;
        }

        50% {}

        100% {
            background: #FFFFFF;
            color: #000;
        }
    }

    @keyframes fadeinout {
        0% {
            background: #999999;
            color: #FFFFFF;
        }

        50% {}

        100% {
            background: #FFFFFF;
            color: #000;
        }
    }
}
