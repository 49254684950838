#whiteboard-calendar {
    .content-card md-content {
        min-height: 100%;
    }
   .header {
       position: relative;
       padding: 0 24px;
         &.Jan {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 45%;
        }
        &.Feb {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 50%;
        }
        &.Mar {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 45%;
        }
        &.Apr {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 48%;
        }
        &.May {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 47%;
        }
        &.Jun {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 48%;
        }
        &.Jul {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 3%;
        }
        &.Aug {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 61%;
        }
        &.Sep {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 58%;
        }
        &.Oct {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 50%;
        }
        &.Nov {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 46%;
        }
        &.Dec {
            background-image: url('/assets/images/backgrounds/header-bg.png');
            background-position: 0 43%;
        }

        .header-content {
            .header-top {
                position: relative;
                z-index: 2;

                .logo {

                    .logo-icon {
                        margin-right: 16px;
                    }

                    .logo-text {
                        font-size: 1.5vw;
                    }
                }
            }

            .header-bottom {
                position: relative;
                z-index: 2;
                margin-bottom: 1em;

                .title {
                    font-size: 1.5vw;
                    min-width: 160px;
                    text-align: center;
                }
            }
        }

        .add-event-button {
            position: absolute;
            right: 18px;
            bottom: -32px;
        }

        md-icon {
            color: #333;
        }
    }

    .content {
        padding: 0;
        max-height: 1050px;


        #calendarView {

            &.fc {

                .fc-widget-header {
                    border: none;
                    color: rgba(0, 0, 0, 0.54);

                    .fc-day-header {
                        text-align: left;
                        border: none;
                        font-weight: 500;
                        padding: 8px;
                    }
                }

                .fc-widget-content {
                    color: rgba(0, 0, 0, 0.54);

                    .fc-day-grid-container {

                        .fc-day-grid {

                            .fc-day-number {
                                text-align: left;
                                padding: 8px 8px 0 8px;
                            }
                        }
                    }

                    .fc-time-grid-container {
                        overflow: hidden;
                        height: auto !important;

                        .fc-axis {
                            font-weight: 500;
                            border: none;
                        }
                    }
                }

                .fc-day-grid-event {
                    margin: 4px 8px 0 8px;
                    padding: 2px 4px;
                    font-size: 13px;
                    color: #FFFFFF;
                }

                .fc-time-grid-event {
                    color: #FFFFFF;
                }

                .fc-month-view {
                    padding: 24px;
                }

                .fc-agenda-view {

                    .fc-widget-header {

                        .fc-day-header {
                            border: 1px solid #DDD;
                            line-height: 50px;
                            font-size: 17px;
                        }
                    }

                    > table > tbody > tr > td.fc-widget-content {
                        border: none;
                    }

                    .fc-minor .fc-widget-content {
                        border-top: none;
                    }

                    .fc-week, .fc-day {
                        height: 100px !important;
                    }

                    .fc-widget-content {
                        height: 50px;
                    }

                    .fc-axis {
                        padding-left: 24px;
                    }
                }
            }
        }
    }

    .card-pop-menu {
        margin-top: -10px;
        margin-right: 8px;
    }
}

 .wb-filter input {
    border-color: rgba(0, 0, 0, 0.8) !important;
    color: rgba(0, 0, 0, 0.8) !important;
    font-size: 1.3em  !important;
    label {
        color: rgba(0, 0, 0, 0.8) !important;
    }
  }

 .wb-filter label {
    color: rgba(0, 0, 0, 0.8) !important;
 }

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-xs) {

    #calendar {

        .header {
            padding: 8px;

            .header-content {

                .header-top {

                    .logo {
                        margin-bottom: 16px;
                    }
                }
            }

            .add-event-button {
                right: 10px;
            }
        }
    }
}

 .job-ticket-badges {
        margin-bottom: 12px;

        .badge {
            min-width: 160px;
            margin-top: 8px;
            margin-right: 8px;
            padding: 4px 8px;
            border-radius: 4px;
            background-color: rgba(0, 0, 0, 0.4);
            color: #FFFFFF;
            //display: inline-block;

            i {
                margin-right: 4px;
            }

            // In-Progress
            &.in-progress {
                background-color: material-color('blue');
            }

            &.completed {
                background-color: material-color('green');
            }

            &.pending {
                background-color: material-color('red');
            }
        }
}

.job-md-subheader {
    margin-left: -12px !important;
}

.bg-whiteboard1 {
    background-color: #FFFFFF;
}

.bg-whiteboard2 {
    background-color: #E5E7E8;
}

// .equipment-whiteboard {
//     background-color: #616161 !important;
// }

/**
 Full Calendar
**/
.fc-event{
    cursor: pointer;
}

.text-filter-input{
    width: 100%;
}
