#skill-status-report {
    padding: 15px;

    md-select.md-default-theme .md-select-value,
    md-select .md-select-value {
        border-bottom: none !important;
    }

    .progress-bar {
        padding: 10px;
    }

    .current-skill-bg-color {
        text-align: center;
        background-color: #2CE080;
    }

    .expiring-skill-bg-color {
        text-align: center;
        background-color: #FCCE5C;
    }

    .expired-skill-bg-color {
        text-align: center;
        background-color: #F85359;
    }

    .lapsed-skill-bg-color {
        text-align: center;
        background-color: #E0E0E0;
    }

    .dataTables_filter {
        display: none;
    }

    .group-view-skill-table {
        border-spacing: 0px 1rem !important;

        thead {
            tr {
                th {
                    border-bottom: 0px !important;
                }
            }
        }

        tbody {
            tr {
                &:first-child {
                    td {
                        border-top: 1px solid rgba(0, 0, 0, 0.12) !important;
                    }
                }
            }

            td {
                border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

                &:first-child {
                    border-left: 1px solid rgba(0, 0, 0, 0.12) !important;
                    border-radius: 10px 0px 0px 10px;
                }

                &:last-child {
                    border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
                    border-radius: 0px 10px 10px 0px;
                }
            }
        }

        .group-view-skill-table-column {
            padding: 0 !important;
            margin: 0 !important;

            .group-view-skill-row {
                line-height: 400%;
                box-sizing: unset !important;
                border-collapse: collapse !important;
                border: none !important;
                margin: -1px 0px 0px !important;
                border-style: none !important;
                padding: 0 !important;
                border-spacing: 0 !important;

                .only-one-skill {
                    border-radius: 0px 10px 10px 0px;
                }

                .top-skill {
                    border-radius: 0px 10px 0px 0px;
                }

                .bottom-skill {
                    border-radius: 0px 0px 10px 0px;
                }

                .custom-tooltiptext-column-right {
                    line-height: 100% !important;
                }

                .skill-name {
                    padding-left: 15px;
                    text-align: left;
                    width: 70%;
                    height: 100%;

                }

                .expiry-date {
                    padding-left: 15px;
                    text-align: left;
                    width: 30%;
                    height: 100%;
                    margin-left: -1px;
                }
            }
        }
    }

    .skill-status-toolbar {
        padding: 10px;
    }

    .branch-dropdown {
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        margin-top: 5px;
        margin-left: -1px;
        margin-right: 15px;
    }

    .search-box {
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        line-height: 300%;
        padding: 3px 10px;
    }

    .generate-button {
        border: 1px solid #e6e6e6;
        border-radius: 8px;
        width: 130px;
        height: 50px;
    }
    
    table[datatable]:not(.dataTable) {
        visibility: hidden;
    }
}
