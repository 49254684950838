#login-v2 {
  height: 100%;
  overflow: hidden;
  background-size: cover;

  #login-v2-intro {
    padding: 128px;

    .title {
      font-size: 42px;
      font-weight: 300;
    }

    .description {
      padding-top: 8px;
      font-size: 14px;
      max-width: 600px;
    }
  }

  #login-v2-form-wrapper {
    width: 416px;
    min-width: 416px;
    max-width: 416px;
    height: 100%;
    background: linear-gradient(135deg,rgba(255,255,255,0.96) 0,rgba(255,255,255,0.96) 100%);
    transition:  all .3s ease-in-out;
    z-index: 1000;

    .login-logo {
      width: 250px;
      height: auto;
      display: block;
      margin: 0 auto 25px auto;
    }

    #login-v2-form {
      padding: 30px 48px 48px 48px;
      min-height: 70%;

      .title {
        font-size: 21px;
        text-align: center;
        padding-bottom: 20px;
      }

      .el-placeholder {
        margin: 16px auto;
      }

      .description {
        padding-top: 8px;
        text-align: center;
      }

      form {
        width: 100%;
        text-align: left;
        padding-top: 32px;

        md-checkbox {
          margin: 0;
        }

        .remember-forgot-password {
          font-size: 13px;
          margin-top: 8px;

          .remember-me {
            margin-bottom: 16px
          }

          .forgot-password {
            font-size: 13px;
            font-weight: 500;
            margin-bottom: 16px
          }
        }

        .md-button {
          width: 100%;
          margin: 16px auto;
          display: block;
        }
      }

      .separator {
        font-size: 15px;
        font-weight: 600;
        margin: 24px auto 16px auto;
        position: relative;
        overflow: hidden;
        width: 100px;
        text-align: center;
        color: rgba(0, 0, 0, 0.54);

        .text {
          display: inline-block;
          vertical-align: baseline;
          position: relative;
          padding: 0 8px;
          z-index: 9999;

          &:before, &:after {
            content: '';
            display: block;
            width: 30px;
            position: absolute;
            top: 10px;
            border-top: 1px solid rgba(0, 0, 0, 0.12);
          }

          &:before {
            right: 100%;
          }

          &:after {
            left: 100%;
          }
        }
      }

      .md-button {

        &.google,
        &.facebook,
        &.microsoft,
        &.alloc8 {
          width: 70%;
          text-transform: none;
          color: #ffffff;
          font-size: 13px;

          md-icon {
            color: #ffffff;
            margin: 0 8px 0 0;
          }
        }

        &.google {
          background-color: #d73d32;
        }

        &.microsoft{
            background-color: #00a2ed;
        }

        &.alloc8{
            background-color: black;
        }

        &.fadeOut {
          opacity: 0;
          display: none;
          transition: opacity 300ms ease-in-out;
          -moz-transition: opacity 300ms ease-in-out;
          -webkit-transition: opacity 300ms ease-in-out;
        }
        &.fadeIn {
          opacity: 1;
          display: block;
          transition: opacity 300ms ease-in-out;
          -moz-transition: opacity 300ms ease-in-out;
          -webkit-transition: opacity 300ms ease-in-out;
        }
      }

      //.register {
      //  margin      : 32px auto 24px auto;
      //  width       : 250px;
      //  font-weight : 500;
      //
      //  .text {
      //    margin-right : 8px;
      //  }
      //
      //  .link {
      //  }
      //}
    }
  }

  #login-v2-reveal {
    background-color: #585858;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
  }

  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transform: scale(0.1) rotate(360deg);
    -moz-transform: scale(0.1) rotate(360deg);
    transform: scale(0.1) rotate(360deg);
    -webkit-transition: all 1000ms 500ms cubic-bezier(0.86, 0, 0.07, 1);
    -moz-transition: all 1000ms 500ms cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 1000ms 500ms cubic-bezier(0.86, 0, 0.07, 1);
  }

  .wrapper.images-loaded {
    opacity: 0;
  }

  .wrapper.loaded {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

footer {
  width: 100%;
  display: table;
  text-align: center;
  bottom: 0;
  padding: 15px 48px;
}

// RESPONSIVE
@media screen and (max-width: $layout-breakpoint-sm) {

  #login-v2 {

    #login-v2-form-wrapper {
      width: 100%;
      min-width: 100%;
      max-width: 100%;

      #login-v2-form {
        text-align: center;
        padding: 24px;

        .md-button {

          &.google,
          &.facebook {
            width: 80%;
          }
        }
      }
    }
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.png-icon{
    height: 25px;
    padding-right: 15px;
}
