#advanceOptions {
    .box-design {
        border-radius: 2px;
        padding: 2px 4px;
        font-size: 11px;
        font-weight: 500;
        white-space: nowrap;
    }

    .headerTitle {
        @extend .md-blue-grey-500-bg;
        @extend .box-design;
        @extend .margin-top-10;
    }

    .sort {
        @extend .md-body-1
    }
}
