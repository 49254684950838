#confirmTicket{
  md-content{
    background-color:transparent;
    overflow: visible;
  }

  md-list-item.md-no-proxy, md-list-item .md-no-style{
    padding:0px;
  }

  .item {
    $border-radius: 2px;

    .selected-item ~ &, &:first-child {
      .user {
        &.selected {
          margin-top: 0;
        }
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }
    &:last-child {
      .user {
        border-bottom-left-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }

    .tile{
      .folded{
        cursor: pointer;
        height: 72px;
        background-color: #ececec;
        margin-bottom: 1px;
        box-shadow: 0 1px 0 0px #999999;
        padding:15px;
      }
    }
    .user {
      background-color: white;
      transition: all 500ms;

      h3, p {
        transition: all 500ms;
      }

      p {
        opacity: 1;
        height: inherit;

        &.ng-hide {
          opacity: 0;
          height: 0;
        }
      }

      &.selected {
        border-radius: $border-radius;

        .selectedItem{
          background-color: #999999;
          color:#ffffff;
        }

        .expanded {
          padding:20px;
          visibility: visible;
          height:700px;
          &, & > * {
            max-height: 700px;
            opacity: 1;
          }

          .md-label {
            border: hidden;
          }
        }

        h3 {
          font-size: 1.8em;
        }
      }

      .expanded {
        visibility: hidden;
        &, & > * {
          max-height: 0;
          opacity: 0;
          transition: all 500ms;
        }
        .split {
          padding: 10px;
        }
      }

      .face {
        border-radius: 30px;
        border: 1px solid #ddd;
        width: 48px;
        height: 48px;
        margin: 16px;
      }
    }
  }
}
