/* List card is a mixin because we want this to be re-usable */

@mixin list-card-daily {
    position: relative;
    width: 250px;
    height: 250px;
    margin: 16px 10px 16px 0;
    background-color: white;
    color: #000;
    border-radius: 2px;
    transition: box-shadow 150ms ease;
    cursor: pointer;
    //cursor: pointer;
    i.s16 {
        line-height: 17px !important;
    }
    .list-card-details-status {
        padding: 0;
        .list-card-label2 {
            height: 18px;
            //border-radius: 4px;
            //margin: 0 6px 6px 0;
        }
    }
    .list-card-details {
        padding: 16px 16px 0 16px;
        .list-card-labels {
            margin-bottom: 6px;
            .list-card-label {
                width: 32px;
                height: 6px;
                border-radius: 6px;
                margin: 0 6px 6px 0;
            }
        }
        .list-card-name {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 12px;
            min-height: 35px;
        }
        .list-card-address {
            min-height: 40px;
            .address-line {
                margin-top: 0;
                margin-bottom: 0;
            }
        }
        
        .list-card-project {
            height: 40px;
        }
        .list-card-badges {
            margin-bottom: 12px;
            .small-font {
                font-size: 0.9em;
            }
            .badge {
                margin-right: 8px;
                padding: 4px 8px;
                border-radius: 2px;
                background-color: rgba(0, 0, 0, 0.4);
                color: #FFFFFF;
                i {
                    margin-right: 4px;
                }
                &.due-date {
                    background-color: material-color('green');
                    &.overdue {
                        background-color: material-color('red');
                    }
                }
                &.ticket-date {
                    background-color: material-color('blue');
                }
                &.check-items {
                    &.completed {
                        background-color: material-color('green');
                    }
                }
                &.ticket-count {
                    background-color: material-color('blue');
                }
            }
        }
        .list-card-members {
            margin-bottom: 12px;
            .list-card-member {
                margin-right: 8px;
                .list-card-member-avatar {
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
    .list-card-resources {
        padding-left: 0;
        .list-card-resource-item {
            color: rgba(0, 0, 0, 0.66);
        }
    }
    .list-card-footer,
    .list-card-footer-edge {
        border-top: 1px solid rgba(0, 0, 0, 0.12);
        padding: 8px 16px 8px 16px;
        .list-card-footer-item {
            height: 100%;
            //margin-right: 12px;
            color: rgba(0, 0, 0, 0.66);
            .value {
                padding-left: 8px;
            }
            &:last-of-type {
                margin-right: 0;
            }
        }
    }
    .list-card-footer-edge {
        padding-top: 12px;
    }
}

#dailyboard {
    position: relative;
    height: 100%;
    background: #E5E7E8;
    padding: 24px;
    overflow-y: hidden;
    //overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    .list {
        max-height: 100%;
        background-color: #EEF0F2;
        color: #000;
        border-radius: 2px;
        transition: box-shadow 150ms ease;
        .list-header {
            height: 64px;
            min-height: 64px;
            padding: 0 0 0 16px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
            .list-header-name {
                max-width: 80%;
                text-overflow: ellipsis;
                overflow: hidden;
                font-size: 15px;
                font-weight: 500;
            }
            .list-header-option-button {
                .md-button {}
            }
        }
        .list-content {
            position: relative;
            overflow: hidden;
            //overflow-y: auto;
            min-height: 0;
            .list-cards {
                position: relative;
                min-height: 32px;
                padding: 0 16px;
                >div {
                    transition: none !important;
                }
                .list-container {
                    -webkit-flex-wrap: wrap;
                    flex-wrap: wrap
                }
            }
        }
    }
    .list-card {
        @include list-card-daily;
    }
    /**
    * Ticket Details for day view (Virtual Repeat Implementation)
    */
    .daily-divStyle-right-pane {
        text-align: right;
        display: inline-block;
        width: 70%;
    }
    ;
    .daily-divStyle-left-pane {
        text-align: left;
        display: inline-block;
        margin-left: 8px;
        width: 25%;
    }
    ;
    /**
    /* Ticket Info
    */
    .daily-ticket-info-container-big {
        height: 200px;
    }
    .daily-ticket-info-container-small {
        height: 180px;
    }
    .repeated-item {
        box-sizing: border-box;
        height: 275px;
        margin-top: 20px;
    }
    #dayview-vertical-container {
        height: 775px;
        width: 100%;
        margin-top: 5px;
    }
}
