
    md-dialog {


       &.ticket-detail-dialog  {

        md-dialog-content {
            display: block;
            position: relative;

        }

        tbody td {
          padding-top: 5px;
          padding-bottom: 5px;
        }

        input[type=text] {
          color : black;
        }

         textarea {
           color : black;
         }

         label {
           color : black;
         }

       }
    }


