#custom-validations {

    .md-api-table {
        max-width: 100%;
        width: 100%;
        border-spacing: 0;
        border-radius: 2px;
        overflow: hidden;

        td, th {
            padding: 12px 16px;
            text-align: left;
        }

        td.no-data {
            text-align : center;
        }

        td {
            vertical-align: top;
        }

        td.description *:first-child {
            margin-top: 0;
        }

        td.description *:last-child {
            margin-bottom: 0;
        }

        tr:nth-child(odd) td {
            background-color: #E3ECF5;
        }

        tr:nth-child(even) td {
            background-color: #D1DEEC;
        }

        th {
            background-color: #475A64;
            color: white;
        }
    }
}

