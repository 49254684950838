#company-view {
  .header {
    position: relative;
    .header-content {
      height: 100%;
    }
  }

  .header-filter{
    //margin-left : 22px;
    padding: 0 2em;
  }


  [scroll] {
    position: fixed;
    top: 0;
  }

  .center {
    overflow: hidden !important;

    .content {
      margin-top: 0;
      padding-top: 20px;
      padding-bottom: 0;
      background-color: inherit;

      .form-wrapper-2 {
        background: #FFFFFF;
        padding: 10px;
      }

      .form-wrapper {
        margin-top: 5px;
        margin-bottom: 24px;
      }
    }
  }

  // Toolbar fixed
  .toolbar {
    background: #FFFFFF !important;
    border-color: rgba(0, 0, 0, 0.12);
    display: block;
    position: relative;

    &-wrapper {
      //height: 56px;
      position: relative;
      overflow: hidden;
      display: block;

      .item {
        display: inline-block;
          margin: auto;
        //margin-right: 24px;
      }
    }
  }
}

