.history-log-page {
    .header {
        margin-left: 10px;
        font-weight: 500;
    }

    .dataTables_filter {
        margin-bottom: -38px;
        padding-top: 4px;
        margin-right: 4px;
    }

    .dt-loading {
        display: none;
    }
}