#job-board-resources {
    padding: 5px;

    .main-container {
        border-left: 2px solid;
        padding: 8px;
        margin: 5px;

        .remove {
            font-size: 20px;
        }
    }

    .main-container-people {
        @extend .main-container;
        border-left-color: steelblue;
    }

    .main-container-equipment {
        @extend .main-container;
        border-left-color: green;
    }
}
