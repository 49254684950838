#lockout-settings {

    .center {
        overflow: hidden;
    }

    overflow: hidden;

    .default-height {
        min-height: 700px;
    }

    .md-chip-input-container {
        width: 40%;
    }

    .content {

        .card {
            margin: 16px auto 48px auto;
            padding: 24px;

            p {
                font-size: 17px;
                line-height: 30px;
            }
        }
    }

    .label {
        background-color: #379ce8;
        color: white;
        padding: 2px;
        border-radius: 3px;
    }

    .global-label {
        background-color: #44d25b;
        color: white;
        padding: 2px;
        border-radius: 3px;
    }

    .warning-header {
        position: relative;
        left: 15px;
        color: #4097f6;
    }

    @-webkit-keyframes spinnerRotate {
        from {
            -webkit-transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
        }
    }

    @-moz-keyframes spinnerRotate {
        from {
            -moz-transform: rotate(0deg);
        }

        to {
            -moz-transform: rotate(360deg);
        }
    }

    @-ms-keyframes spinnerRotate {
        from {
            -ms-transform: rotate(0deg);
        }

        to {
            -ms-transform: rotate(360deg);
        }
    }

    .spin {
        -webkit-animation-name: spinnerRotate;
        -webkit-animation-duration: 5s;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        -moz-animation-name: spinnerRotate;
        -moz-animation-duration: 5s;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
        -ms-animation-name: spinnerRotate;
        -ms-animation-duration: 5s;
        -ms-animation-iteration-count: infinite;
        -ms-animation-timing-function: linear;
    }

    .is-saving {
        opacity: 0.3;
    }

    .is-saved {
        opacity: 1;
    }
    .input-error-message {
        padding-left: 10px;
    }
}
