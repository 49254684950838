#award-view {
    .field-note {
        position: relative;
        font-size: 12px;
        margin-left: 30px;
        color: rgba(0, 0, 0, 0.38);
        margin-top: -12px;
    }

    .payroll-options {
        & md-input-container {
            margin: 0;
            display: block;
        }

        & md-checkbox {
            margin: 0 0 8px 0;
        }
    }

    #branches {
        width: 100%;
    }

    .buttons-bar button {
        min-width: 150px;
        min-height: 35px;
    }

    .card {
        @extend .md-whiteframe-2dp;
        @extend .white-bg;
        @extend .m-10;
    }

    .instruction-warning {
        color: #ff8100;
     }
}
