/*----------------------------------------------------------------*/
/*  ms-scroll
/*----------------------------------------------------------------*/

.ms-scroll2 {
    overflow: hidden !important;
    //background-color: lightblue !important;
}

.ms-color {
    //background-color: aliceblue !important;
}

