#sidenav {
  .list-with-divisions{
    position : relative;
    top : -20px;
  }

  .input-with-divisions{
    position : relative;
    top : -40px;
  }
}
