.contactsArea {

    .ps-scrollbar-x-rail,
    .ps-scrollbar-y-rail {
        opacity: 0.6 !important;
    }

    .big-screen {
        min-height: 800px;
        max-height: 800px;
    }

    .icon-uniform-color {
        color: #475A64 !important;
    }

    .contacts-area-big {
        @extend .md-whiteframe-3dp;
        @extend .layout-margin;
        @extend .layout-padding;
        @extend .grey-200-bg;
        height: 800px;
        margin-bottom: -5px;

        .icon-not-added {
            color: #475A64;
        }

        .icon-added {
            color: #50A042;
        }

        md-card-header {
            position: relative;
            top: 20px;
        }

        md-input-container {
            flex: 0% !important;
            flex-grow: 0 !important;
        }

        md-card-content {
            overflow-y: hidden;
        }

        .main-list {
            height: 600px;
        }

        .icon-clickable {
            cursor: pointer;
        }

        .header-text {
            left: 5px;
            top: 1px;
            position: relative;
            font-weight: bold
        }

        .list-item {
            padding: 0px;
            margin: 0px;

            &:hover {
                background-color: whitesmoke;
            }

            .list-name {
                &:hover {
                    position: relative;
                    margin-left: 15px;
                    -webkit-transition: 1s;
                    -moz-transition: 1s;
                    transition: 1s;
                }
            }
        }
        .status {
            width: auto;
            font-size: 12px;
            white-space: nowrap;
            padding: 2px 5px;
            border-radius: 2px;
            text-align: left;
            display: inline-block;
            color: white;
            background-color: #0b93d5;
        }

    }
}
