// Email Sender Style sheet     
#invoice-email-sender {
     
    md-icon[md-font-icon].s16, i.s16 {
        position: relative;
        top: 1px;
    }

    .right-pane-menus {
        display: flex;
    }

// Demo start

     #vertical-container {
        //height: 800px;
        width: 100%;
    }
      
    //  .repeated-item {
    //     border-bottom: 1px solid #ddd;
    //     box-sizing: border-box;
    //     height:  118px;
    //     padding-top: 10px; 
    //  }
      
    //    md-content {
    //     margin: 16px; 
    //    }
      
    //   md-virtual-repeat-container {
    //     border: solid 1px grey; 
    //   }
      
    //   .md-virtual-repeat-container .md-virtual-repeat-offsetter div {
    //         padding-left: 16px; 
    //    }

    // Demo end

    .main-row-item {
        height: 118px;
        border-bottom:gainsboro 1px solid !important;
        margin-bottom: 1px;
    }
     
    .unsent-item {
        border-left: 3px solid  #607D8B;//material-color('red');
    }

    .item {
        padding: 0px 10px;
        margin: 0 16px;
        
        .item-title {
            font-size: 14px;
            font-weight: 500;
        }
        .item-body {
            padding-top: 10px;
        }
        .info-body {
            background-color: cyan;
        }
        .check-itm {
            margin-left: 0px;
        }
        .date {
            color: gainsboro;
        }
        .labels {
                background: #FAFAFA;
                text-align: center;
                padding-top: 5px;
                
                .label {
                    display: inline-block;
                    font-size: 11px;
                    padding: 0 5px;
                    font-weight: bold;
                    color: #FFFFFF;
                    
                    &:first-child {
                        margin-left: 0;
                    }
                    &.sent {
                        background-color: material-color('green');
                    }
                    &.unsent {
                        background-color: material-color('orange');
                    }
                    &.failed {
                        background-color: material-color('red');
                    }
                    &.skipped {
                        background-color: material-color('purple');
                    }
                }
        }

        .avatar {
            min-width: 32px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            display: inline-block;
        }
        .av-red {
            background-color: material-color('red');
        }
        .av-cyan {
            background-color: #7E57C2; //material-color('cyan');
        }
        .av-green {
            background-color: #607D8B;//material-color('teal');
        }
        .av-orange {
            background-color: material-color('orange');
        }
        .av-blue {
            background-color: material-color('blue');
        }

        /**
        * Tooltip2 for ReactJs
        */
        a {
            color: #900;
            text-decoration: none;
        }

        a:hover {
            color: red;
            position: relative;
        }

         a[title]:hover:after {
            width: 120px;
            text-align: center;
            content: attr(title);
            padding: 4px 8px;
            color: #fff;
            position: absolute;
            white-space: nowrap;
            border-radius: 4px;
            background-color: #616161;
            z-index: 999999;
            top: 150%;//-220%;
            left: 50%;
            margin-left: -60px;
            font-size: 10px;
        }
    }

    &.unread {
        background: #FFFFFF;

        .info {

            .name,
            .subject {
                font-weight: 700;
            }

            .message {

                .labels {
                    background: #FFFFFF;
                }
            }
        }
    }

    &.selected {
        background: #FFF8E1;

        .info {

            .message {

                .labels {
                    background: #FFF8E1;
                }
            }
        }
    }

    &.current-thread {
        background: #E3F2FD;

        .info {

            .message {

                .labels {
                    background: #E3F2FD;
                }
            }
        }
    }

    .info {
        overflow: hidden;
        width: 0;
        margin: 0 16px;

        .name {
            font-size: 15px;
            font-weight: 500;
            padding-bottom: 8px;

            .avatar {
                min-width: 32px;
                width: 32px;
                height: 32px;
                line-height: 32px;
            }

            .has-attachment {
                margin-left: 8px;
                transform: rotate(90deg);
            }
        }

        // .subject {
        // }

        .message {
            position: relative;
            color: rgba(0, 0, 0, 0.54);

            .labels {
                position: absolute;
                background: #FAFAFA;
                top: 0;
                right: 0;
                padding-left: 6px;

                .label {
                    display: inline-block;
                    font-size: 11px;
                    padding: 0 5px;
                    margin-right: 6px;

                    &:first-child {
                        margin-left: 0;
                    }
                }
            }
        }
    }

    .time {
        margin: 0 8px 6px 8px;
    }

    .actions {

        .md-icon-button {
            margin: 0;
            padding: 0;
            width: 32px;
            height: 32px;
        }
    }


    .thread-list {
        position: relative;
        padding: 0; 
    }

    .no-threads {
        background: #FAFAFA;
        width: 100%;
        text-align: center;
        padding: 32px;
        font-size: 20px;
    }

    .loading-threads {
        //background: #FAFAFA;
        width: 100%;
        text-align: center;
        padding: 32px;
        //font-size: 20px;
    }

    .thread {
        background: #FFF;
        position: relative;
        cursor: pointer;

        
      }

   
}
     