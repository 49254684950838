#quick-panel {
    .todo-tab {
        a {
            float: right;
        }

        .main {
            position: absolute;
            width: 100%;
            top: 0;
            bottom: 0;

            .subheader {
                font-size: 1.5em;
            }

            .contact {
                position: relative;

                .status {
                    position: absolute;
                    top: 18px;
                    left: 44px;
                    width: 18px;
                    height: 18px;
                    border: 3px solid #ffffff;
                    border-radius: 50%;
                    z-index: 10;

                    &.online {
                        background-color: #00c853;
                    }

                    &.offline {
                        background-color: #d50000;
                    }

                    &.away {
                        background-color: #ff6d00;
                    }
                }
            }

            .unread {

                h3,
                .last-message {
                    font-weight: bold;
                }
            }

            .datestamp {
                text-align: right;
                font-style: italic;
            }
        }
    }
}
