#composedEmailTemplate{
    width: 40%;

    #dialogContent_composedEmailTemplate{
        padding: 0px 24px 5px 24px;
    }

    md-input-container {
        position: relative;
        padding: 2px;
        margin: 10px 0;
        vertical-align: middle;
    }

    .email-body{
        margin-left: 5px;
        font-size: 15px;
    }

    .email-input-label{
        transform: translate3d(0, 28px, 0) scale(1);
        transition: none;
        font-size: 15px;
    }
    
    .email-input-headers{
        padding-left: 30px;
        padding-top: 3px;
        font-size: 15px;
    }
    .email-input-headers{
        padding-left: 30px;
        padding-top: 3px;
        font-size: 15px;
    }

    input[name~=emailSubject]{
        padding-left: 65px;
        padding-top: 3px;
        font-size: 15px;
    }

    md-icon[md-font-icon], i {
        font-size: inherit;
        width: 24px;
        height: 24px;
        min-width: 24px;
        min-height: 24px;
        line-height: 24px;
    }
    
    .md-button.md-default-theme.md-accent.md-fab, 
    .md-button.md-accent.md-fab, 
    .md-button.md-default-theme.md-accent.md-raised, 
    .md-button.md-accent.md-raised {
        color: #330202;
        background-color: #2d323e;
        box-shadow: none;
    }

    .md-button.md-default-theme.md-accent.md-fab:not([disabled]) md-icon, 
    .md-button.md-accent.md-fab:not([disabled]) md-icon, 
    .md-button.md-default-theme.md-accent.md-raised:not([disabled]) md-icon, 
    .md-button.md-accent.md-raised:not([disabled]) md-icon {
        color: #ff4031;
    }

    .dialog-actions{
        background-color: #2d323e;
    }

    .btn-send{
        background-color: #9f05ffcf;
        color: white;
        border-radius: 1rem;
        position: absolute;
        left: 0;
        margin-left: 15px;
    }
}