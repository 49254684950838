#manage-recipient {
    // .dialog-add-email {
    //     //position: relative; 
    //     //top: 5px !important;
    // }

    .manage-recipients-table {
        min-width: 500px;
    }

    .md-inline-form md-checkbox {
        margin: 5px 20px 18px;
    }
}