.payroll-settings {
    .payroll-format {
        #ExportFormat{
            font: 12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
        }
    }

    .timesheet-headings {
        .header-codes {
            word-break: break-all;
            word-wrap: break-word;
        }
    }

    .payroll-export-codes {
        .payroll-export-codes-sheet {
            transform: translate3d(0,0,0);
        }
    }

    .flex-sheet-holder div[wj-part='container'] {
        height: 500px !important;
    }

    .ace_editor {
        height: 300px;
        border: 1px solid;
        padding: 10px;
        box-shadow: 1px 1px 1px 1px #888888;
    }
}
