.resource-like{
  a {
    text-decoration: none;
    cursor : pointer;
  }

  .checkbox{
    color : #455a64;
  }
}
