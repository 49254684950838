#award-conditions-dialog {

    .draggable-container {

        .drag-handle {
            cursor: grab;
        }
    }

    .card {
        @extend .white-bg;
        @extend .mb-10;
        border-radius: 8px;
        font-size: 13px;
        border: 1px solid #e6e6e6;

        .card-row {
            @extend .p-10;
        }

        hr {
            border: 1px solid #e6e6e6;
            border-top: transparent;
        }

        .card-full-button {
            @extend .pt-8;
            @extend .pb-8;
            @extend .mb-10;
            @extend .text-uppercase;
            @extend .bold-text;
            @extend .layout-align-center-center;
            @extend .layout-row;
            cursor: pointer;
            border-radius: 5px;
            border: 1px solid #e6e6e6;

            .button-text {
                @extend .font-size-12;
                @extend .pl-10;
            }
        }
    }

    .condition-operator {
        border-radius: 20px;
        padding: 8px;
        background-color: #e6e6e6;
        @extend .font-size-10;
    }

    .condition-value {
        .icon-check {
            color: #00d488;
        }

        .icon-close {
            color: #ff4271;
        }
    }

}
