.add-package-group {
    border: 2px solid black;

    .btn-add-package-item {
        line-height: 20px !important;
        width: 20px !important;
        height: 20px !important;
        min-height: 0;
        text-align: center;

        .icon-plus {
            font-size: 10px;
            cursor: pointer;
            &:before {
                content: "\e9ab";
                float: left;
                line-height: 20px;
            }
        }
    }

    .btn-delete-package-group {
        margin-left: -5px;
        padding-left: 0;
    }

    .package-items-header {
        .material-icons {
            cursor: pointer;
        }
    }
    .package-items-wrap {
        .package-items-btn {
            text-align: right;
            color: #333;
            i {
                cursor: pointer;
            }
        }
        .package-item {
            background: rgb(69,90,100);
            border-radius: 5px;
            color: #FFF;
            .item-remove-icon {
                .material-icons {
                    display: inline-block;
                    position: relative;
                    padding: 2px;
                    margin: 18px 0;
                    vertical-align: middle;
                    cursor: pointer;
                }
            }
            .item-name {
                display: inline-block;
                position: relative;
                padding: 2px;
                margin: 18px 0;
                vertical-align: middle;
            }
            .item-quantity {
                cursor: pointer;
                border-color: #FFF;
                color: #FFF;
            }
        }
    }
}
