#manual-invoice {
    .content {
        margin-top: 5px;
        padding-top: 20px;
        .form-wrapper2 {
            background: #FFFFFF;
            padding: 16px;
            .form-title {
                font-size: 21px;
                padding: 8px 0;
            }
            .md-button {
                margin-left: 0;
            }
        }
    }
    .toolbar-custom {
        background-color: #FFFFFF;
    }
    .toolbar-wrapper {
        margin-left: 15px;
    }
    .sub-titles {
        color: rgba(0, 0, 0, 0.54);
    }
    .invoice-detail {
        cursor: pointer;
    }
    .invoice-resource-table {
        margin-top: 0 !important;
        margin-bottom: 30px;
        thead {
            tr {
                th {
                    padding: 0 !important;
                }
            }
        }
    }
    .invoice-table {
        //margin-top: 16pt;
        thead {
            margin-bottom: 5px;
            tr {
                th {
                    font-size: 10pt;
                    max-width: 60pt;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
        }
        tbody {
            tr {
                td {
                    border-bottom: 0;
                    padding: 5px 8px;
                    &:first-child {
                        padding-left: 0;
                    }
                    &:last-child {
                        padding-right: 0;
                    }
                }
            }
            tr:nth-child(odd) {
                background-color: #f4f4f4;
            }
            tr:nth-child(even) {
                background-color: #fff;
            }
        }
        /* Put line spacing before body */
        tbody:before {
            content: "-";
            display: block;
            line-height: 1em;
            color: transparent;
        }
        .title {
            font-size: 10pt;
        }
        .detail {
            margin-top: 4pt;
            font-size: 9pt;
            max-width: none;
        }
        .qty-col {
            width: 100px;
        }
        .unit-price-col {
            width: 120px;
        }
        .amt-col {
            width: 130px;
        }
        .start-time-col {
            width: 180px;
        }
        .end-time-col {
            width: 180px;
        }
        .hrs-col {
            width: 180px;
        }
    }
    .invoice-table-footer {
        margin: 32px 0 0 0;
        tr {
            td {
                text-align: right;
                font-size: 17px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.54);
                border-bottom: none;
                padding: 8px 8px;
                &:first-child {
                    text-align: left;
                }
            }
            &.discount {
                td {
                    padding-bottom: 32px;
                }
            }
            &.total {
                td {
                    padding: 32px 8px;
                    border-top: 1px solid rgba(0, 0, 0, 0.12);
                    font-size: 24px;
                    font-weight: 300;
                    color: rgba(0, 0, 0, 1);
                }
            }
        }
    }
}