.connector {
    flex: 1;
    margin-right: 10px;
    max-width: 200px;
    display: flex;
    flex-direction: column;

    & > img {
        margin: 10px;
        height: 120px;
        width: auto;
        
        &.half-opacity {
            opacity: 50%;
        }
    }

    & > button {
        margin: auto;
        height: 26px;
        border-radius: 5px;
    }
}