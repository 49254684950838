.related_ticket_billing {

    .single-day-table {
        padding: -5px;
    }

    .table-qty tr, td {
        height : 5;
    }

    .ticket_data  {
        min-height : 200px;
        width: 200px;
        min-width: 200px;
        max-width: 200px;
        position : relative;
        margin-left : 15px;
        margin-top : -30px;
    }

    .week-group {
        mei-height : 1000px;
    }

    .header_style  {
        text-align : right;
        background-color  : #2977C0;
        height : 15px;
        width : 100%;
    }

    .header_style_locked  {
        text-align : right;
        background-color  : grey;
        height : 15px;
        width : 100%;
    }

    .week_container_style {
        min-height : 500px;
        width: 220px;
        min-width: 220px;
        max-width: 220px;
        padding-right: 10px;
    }

    .blur {
        opacity : 0.3
    }

    .ticket-item {
        position : relative;
        margin-top : 10px;
    }

    .ticket-holder {
        position : relative;
        margin-top : -25px;
    }

    .ticket-detail {

        .card-company {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 12px;
            height: 35px;
        }

        .small-font {
            font-size: 0.9em;
        }

        .address {
            height : 40px;
            color : grey;
            position : relative;
            top : -20px;
        }

        .ticket-divider {
            margin-bottom : 10px;
        }

        .ticket-main-detail{
            background-color : white;

            .address-section{
                min-height : 50px;
                height : auto;
            }

            .company {
                min-height : 50px;
                height : auto;
            }
        }

        .ticket-other-detail {
            background-color : white;
            min-height : 40px;
            max-height : 40px;
        }

        .bill-separator {
            margin-top : 10px;
        }

        .item-separator {
            margin-top : 5px;
        }

        .bill {
            height : auto;
        }

        .badge:hover{
            background-color: transparent;
        }

        .badge {
            background-color : white;
            min-height : 100px;
            height : auto;
            cursor: pointer;

            .side {
                max-width : 2px;
                position : relative;
            }

            .bill-items {
                position : relative;
                padding-left : 40px;
                top : 50px;
                font-size: 0.7em;
                color : grey
            }

            .inv-text{
                visibility : hidden;
            }

            .bill-table{
                width : 100%;
                table-layout: fixed;
                position:relative;
                top : 35px;
                left : -10px;
                padding-bottom : 30px;
                margin-bottom : 30px;
            }

            .bill-table-item{
                margin : 0px;
                padding : 0px;
                width : 25px;
                white-space: nowrap;
            }

            .bill-table-item2{
                white-space : nowrap;
            }

            .bill_header{
                position : relative;
                margin-top : -30px;
                height : 10px;
                font-weight : bolder
            }

            .icon-pointer {
                position : relative;
                top : 3px;
            }
        }
    }
}
