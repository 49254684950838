#business-edit {
    .avatar {
        &.huge-rectangle {
            border-radius: 0;
            width:auto;
            height: auto;
            max-width: 250px;
            max-height: 96px;
        }
    }

    .over-flow-visible{
        overflow: visible;
    }

    .float-right {
        float: right;
    }

    .float-left {
        float: left;
    }

    .margin-top-minus-30 {
        margin-top: -30px;
    }

    .form-label {
        font-size: 1.6rem;
        color: rgba(0, 0, 0, 0.38);
    }

    .md-subhead {
        font-weight: 500;
        color: rgba(0, 0, 0, 0.54);
    }

    input[type="file"] {
        border: none !important;
    }

    .mandatory {
        color: red;
    }

    .container {
        background-color: rgba(0, 0, 0, 0.02);
        padding: 10px;
    }

    .instruction-info {
        position: relative;
        font-size: 12px;
        color: #00000061;
    }

    .dropdown-instruction {
        position: relative;
        font-size: 12px;
        margin-left: 3px;
        margin-top: -15px;
        color: #00000061;
        overflow-wrap: normal;
    }

    .checkbox-group-instruction {
        position: relative;
        font-size: 12px;
        margin-left: 110px;
        margin-top: -20px;
        color: #00000061;
        overflow-wrap: normal;
    }

    .instruction-warning,
    .instruction-warning label {
        color: #ff8100;
    }

    #uploadLogo{
        margin-top:-50px;
    }
}
