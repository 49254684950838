.resource-like{
  a {
    text-decoration: none;
    cursor : pointer;
  }

  .ps-scrollbar-x{
    display: none !important;
  }

  .ps-scrollbar-y{
    display: none !important;
  }
}
